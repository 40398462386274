import { useRef, useCallback, useReducer, Reducer } from "react";
import { Canceler } from 'axios';
import { TFunction } from 'react-i18next';

import { useAPI, debounce, Modification } from './index';
import { useValidator } from './validator';
import { check } from './check';

import { TaskScheduling } from './types';



const fieldValidators = {
    'summary': {
        require: true,
        validators: [
            (value: string, t: TFunction) => check(value, t).required().isString().min(3).error
        ]
    }, 
    'status': {
        require: true,
        isValid: (value: string, t: TFunction) => check(value, t).required().isString().values(['TODO', 'IN_PROGRESS', 'DONE']).error
    }, 
    'due_date': {
        require: false,
        //isValid: (value: string, t: TFunction) => check(value, t).required().isDate().after('2021-12-01').error
    }, 
};

const emptyScheduling: TaskScheduling = {
    id: undefined,
    trigger_type: 'TIME', 
    summary: 'Task...',
    attachments: [], 
}

type StateType = {
    scheduling: TaskScheduling;
    isLoading: boolean;
    isModified: Modification;
    errorMessages: any;
}

const initialState: StateType = {
    scheduling: emptyScheduling,
    isLoading: false,
    isModified: Modification.Untouched, 
    errorMessages: {}
}

const reducer = (state: StateType, action: any): StateType => {
    switch (action.type) {
        case 'INIT':
            return { scheduling: action.payload, isLoading: state.isLoading, isModified: state.isModified, errorMessages: {} }
        case 'LOADING':
            return { ...state, isLoading: true }
        case 'LOADED':
            return { scheduling: action.payload, isLoading: false, isModified: Modification.Untouched, errorMessages: {} }
        case 'MODIFIED':
            return { scheduling: action.payload, isLoading: state.isLoading, isModified: Modification.Modified, errorMessages: state.errorMessages }
        case 'VALIDATED':
            return { ...state, errorMessages: action.payload }
        case 'SAVED':
            return { ...state, isModified: Modification.Saved }
        default:
            return state;
    }
}

export const useScheduling = (onForbidden?: Function, onError?: Function) => {

    const [state, dispatch] = useReducer<Reducer<StateType, any>>(reducer, initialState);
    const cancelRef = useRef<Canceler>();

    const { fetchData, updateData, createData } = useAPI({ cancelRef, onError, onForbidden });
    const { validate } = useValidator(fieldValidators);

    const _init = (scheduling: TaskScheduling) => {
        dispatch({ type: 'INIT', payload: scheduling });
    }

    const _load = (id: number) => {
        if (!id) return;
    
        dispatch({ type: 'LOADING' });

        fetchData(`/api/scheduling/${id}`).then(resp => {
            const recurrence = resp.data.recurrence;
            const _data = {
                ...resp.data,
                has_recurrence: (recurrence?.freq || recurrence?.incremental_value) ? 'RECURRENCE' : 'SINGLE'
            }

            dispatch({ type: 'LOADED', payload: _data });
        }).catch((error: any) => {});
    }

    const saveScheduling = (data: TaskScheduling) => {

        const schedulingId = data.id;

        const _data = {
            summary: data.summary,
            description: data.description,
            asset: data.asset, 
            accountable: data.accountable, 

            trigger_type: data.trigger_type,
            start_date: (data.trigger_type === 'TIME') ? data.start_date : undefined,  
            initial_value: (data.trigger_type === 'METER') ? data.initial_value : undefined,
        
            recurrence: (data.has_recurrence === 'RECURRENCE') ? {
                ...((data.trigger_type === 'TIME') ? {
                    freq: data.recurrence?.freq,
                    interval: data.recurrence?.interval,
                    by_day: (data.recurrence?.freq === 'WEEK') ? data.recurrence?.by_day : undefined, 
                    by_monthday: (data.recurrence?.freq === 'MONTH') ? data.recurrence?.by_monthday : undefined, 
                } : {
                    incremental_value: data.recurrence?.incremental_value,
                })
            } : undefined, 

        };

        if (schedulingId) {
            updateData(`/api/scheduling/${schedulingId}`, _data)
            .then((resp: any) => {
                dispatch({ type: 'SAVED' });
            })
            .catch((error: any) => {});
        } else {
            createData(`/api/scheduling`, _data)
            .then((resp: any) => {
                dispatch({ type: 'SAVED' });
            })
            .catch((error: any) => {});
        }

        dispatch({ type: 'SAVED' });
    }

    const debouncedSave = useCallback(
        debounce((_scheduling: any) => {
            const _errors = validate(_scheduling);
            if (Object.keys(_errors).length === 0) {
                _scheduling.id && saveScheduling(_scheduling);
            }
        }, 6000), 
        []
    );

    const _update = (changes: any) => {
        const _data = Object.assign({}, state.scheduling, changes);

        console.log('update: ', _data)

        dispatch({ type: 'MODIFIED', payload: _data });
        debouncedSave(_data);
    }

    const _save = (_data: TaskScheduling) => {
        debouncedSave.cancel();
        saveScheduling(_data);
    }

    /*
    const _validateTask = () => {
        const _errorMessages = validate(state.task);
        dispatch({ type: 'VALIDATED', payload: _errorMessages });

        console.log('_validateTask() - _errors: ', _errorMessages);

        for (const fieldName in _errorMessages) {
            if (_errorMessages[fieldName]) return false;
        }
        
        return true;
    }
    */

    return { 
        scheduling: state.scheduling, 
        initScheduling: _init, 
        loadScheduling: _load, 
        update: _update, 
        saveScheduling: () => _save(state.scheduling),

        isLoading: state.isLoading, 
        isModified: state.isModified, 
        //validateTask: _validateTask,
        errors: state.errorMessages, 
    };

}

