import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import axios, { Canceler } from 'axios';

import { Page, ClearContent, Button, Helpy, Form, Text, TextArea } from '../../components';
import { useUser } from '../../providers/UserProvider';
import { useModal } from '../../providers/ModalProvider';
import { useToast } from '../../providers/ToastProvider';

import AssetDetails from '../AssetsPage/AssetDetails';
import TaskDetails from '../CalendarPage/TaskDetails';

const Illustration = ({ name }: any) => {
    const style = {
        width: '120px', 
        margin: '10px'
    }
    return  <img style={style} src={`images/illustrations/${name}.jpg`} />
}

const initTask = (user: any) => ({
    id: undefined,
    summary: 'Új feladat',
    status: 'TODO',
    accountable: {
        id: user.userId,
        name: user.fullName, 
    }, 
    attachments: [], 
});

const HelpwiseForm = ({ init, url }: any) => {

    const [form, setForm] = useState<any>(init || {});
    const [sent, setSent] = useState<boolean>(false);

    const handleChange = (value: any, name: string) => {
        setForm((form: any) => ({
            ...form,
            [name]: value
        }));
    }

    const handleSend = async () => {

        /*
        const formData = new FormData();
        formData.append("Content-Type", 'multipart/form-data');
        Object.entries(form).forEach(([k, v]) => {
            formData.append(k, v);
        });*/
        //formData.append('File', file);

        await axios.post(url, {
            name: form.name,
            email_address: form.email_address,
            comment: form.comment
        }, {
        }).then((resp: any) => {
            console.log(resp);
            setSent(true);
            //return resp.data;
        }).catch((error: any) => {
            console.error(error);
        });
    }

    return <div>
        { sent ? <p>Köszönjük üzenetét!</p> : <>
            <div className='row'>
                <Form.Group>
                    <Form.Label>{ 'Név' }</Form.Label>
                    <Text name='name' value={form.name} onChange={handleChange} />
                </Form.Group>
            </div>
            <div className='row'>
                <Form.Group>
                    <Form.Label>{ 'Email' }</Form.Label>
                    <Text name='email_address' value={form.email_address} onChange={handleChange} />
                </Form.Group>
            </div>
            <div className='row'>
                <Form.Group>
                    <Form.Label>{ 'Fejlesztési ötletek' }</Form.Label>
                    <TextArea name='comment' value={form.comment} onChange={handleChange} placeholder='Egy hasznos új funkció lenne, ha...' />
                </Form.Group>
            </div>
            <div className='row'>
                <Button variant='primary' onClick={handleSend}>Elküld</Button>
            </div>
        </> }
    </div>
    
    
    /*
    <form method="POST" name="fa-form-1" action="https://webhook.helpwise.io/forms/50b2628a-e975-4413-ba54-24f856364336" enctype="multipart/form-data" accept-charset="utf-8">
        <div><label for="név:">Név:</label><input type="text" name="név:"></div> <div>
            <label for="email">Email:</label><input type="email" name="email"></div> 
        <div><label for="fejlesztési ötletem:">Fejlesztési ötletem:</label><textarea name="fejlesztési ötletem:"></textarea></div> 
        <div><label for="csatolmány">Csatolmány</label><input type="file" name="csatolmány"></div>
        <div><input type="submit" value="Send"></div>
    </form>)*/
}

export default function DashboardPage({ location }: any) {

    const { t } = useTranslation();
    const modal = useModal();
    const user = useUser();

    const handleNewAsset = () => {
        modal.showModal(<AssetDetails id={null} onClose={() => modal.closeModal()} />);
    }

    const handleNewTask = () => {
        modal.showModal(<TaskDetails id={null} task={initTask(user)} onClose={() => modal.closeModal()} />);
    }

    return (<Page title={ t('translation:pages.dashboard') } to={ location.pathname }>
        <ClearContent>
            <div className='row'>
                <div className="card">
                    <div className='row'>
                       <Illustration name={'asset'} />
                        <p>Kezdje meg felvinni az eszközöket és berendezéseket a rendszerbe:
                            <br/>
                            <br/>
                            <Button onClick={handleNewAsset}>Új eszköz</Button>
                        </p>
                    </div>
                </div>

                <div className="card">
                    <div className='row'>
                       <Illustration name={'task-list'} />
                        <p>Hozzon létre egy új feladatot a naptárba:
                            <br/>
                            <br/>
                            <Button onClick={handleNewTask}>Új feladat</Button>
                        </p>
                    </div>
                </div>

                <div className="card">
                    <Illustration name={'long-paper'} />

                </div>
            </div>

            <div className='row'>
                <div className="card">
                    <h2>Írja meg nekünk ötleteit, kéréseit!</h2>
                    <HelpwiseForm 
                        init={{ name: user.fullName, email_address: user.emailAddress, comment: '' }} 
                        url='/api/health/message'    
                    />
                </div>

                <div className="card">
                    <h2>Újdonságok</h2>
                    <p><strong>2021.11.18.</strong> Elérhető az ingyenes próbaév ajánlat a Techference alkalmából. Részletek itt: <a href='https://maintenapp.com/hello' target='_blank'>https://maintenapp.com/hello</a></p>
                    <p><strong>2021.11.11.</strong> Elérhető a szabad cimkézés. A cimkék az 'Új címke' gombra kattintva hozhatóak létre.</p>
                    <p><strong>2021.11.05.</strong> Az ismétlődő feladatok már időtartam alapon is megadhatók, pl. gépóra vagy kilométer. Ezt be lehet rögzíteni minden eszközhöz.</p>
                    <p><strong>2021.11.02.</strong> Az adatlapra képet is lehet csatolni.</p>


                    <p><strong>2021.11.01.</strong> Elérhető a dokumentumtár, amiben minden dokumentum kereshető is. Az egyes eszközökhöz továbbra is fel lehet csatolni a gépkönyveket, használati útmutatókat, és a karbantartás feljegyzéseit.</p>
                    <p><strong>2021.10.25.</strong> Az észrevételek alapján átdolgoztuk a karbantartás elvégzésének a dokumentálását: ha egy munka elkészült, akkor a naptárban készre állítódik, de még várja a kapcsolódó dokumentum feltöltését. Haa dokumentálás is ki van pipálva, akkor lesz teljesen lezárt állapotú a feladat.</p>
                    <p><strong>2021.10.11.</strong> Elindult a publikus béta verzió! 
                    Mindazok számára, akik fantáziát látnak ebben a termékben elérhető az egyösszegű élethosszig szóló előfizetés, ami minden jövőbeni fejlesztést is tartalmaz. Ez egyben egy kis támogatás is nekünk az induláshoz, amikor még csak a munka van a szoftverrel :-)
                    Az egyösszegű áras ajánlat itt érhető el.
                    </p>

                </div>
            </div>

        </ClearContent>
    </Page>);
}
