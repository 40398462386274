import React, { useState, useMemo, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

import { useModal } from '../../providers/ModalProvider';
import { useToast } from '../../providers/ToastProvider';

import { Dialog, CloseButton, 
    Form, Button, Text, TextArea, EditableTitle,
    TagsInput, UnitInput, Tabs, 
    FileList, 
    LoadingOverlay, Spinner, 
} from '../../components';

import TaskScheduler from './TaskScheduler';
import SchedulingList from './ScheduledTaskList';

import { useAsset, useAssetGroupsQuery, useAssetLogs, useAssetSchedulings } from '../../api/asset';
import { today } from '../../utils';

const SaveController = ({ modified, save: saveAsset, t }: any) => {

    const handleClick = () => {
        saveAsset();
    }

    return <div>{ modified == 1 ? 
        <button className='btn btn-small btn-primary' onClick={handleClick}>{ t('translation:details.btnSave') }</button> : modified == 2 ? <span>{ t('translation:details.lblSaved') }&nbsp;</span> : '' }
    </div>;
}

const AssetDetails = ({ id, onClose }: any) => {

    const { t } = useTranslation();
    const modal = useModal();
    const { showToast } = useToast();

    const { asset, isLoading, initAsset, loadAsset, updateAsset, saveAsset, cancelSaving, 
        isModified, validateAsset, errors } = useAsset(() => showToast('Nincs jogosultsága a művelethez.'), () => showToast('Kommunikációs hiba.'));
    
    const { name, make, model, inventory_number, comment, loadValue, loadUnit, groups, attachments } = asset;
    const { assetGroups, createGroup } = useAssetGroupsQuery('');
    //const { tasks } = useAssetTasks(id, () => showToast('Nincs jogosultsága a művelethez.'), () => showToast('Kommunikációs hiba.') );

    const { logs: loadValues, insertLog } = useAssetLogs(id);
    const { schedulings, loadAssetSchedulings } = useAssetSchedulings();

    useEffect(() => {
        if (id) {
            loadAsset(id).then(() => {
                loadAssetSchedulings(id);
            });
        } /*else if (_initTask) {
            loadAsset(_initTask);
        }*/
    }, []);

    const handleClose = () => {
        cancelSaving();
        onClose && onClose();
    }

    const handleChange = (value: any, name: string) => {

        if (name === 'loadValue') return;

        if (name === 'groups') {
            updateAsset({ 'groups': value });
            return;
        }

        updateAsset({ [name]: value });
    }

    const handleSave = () => {
        saveAsset();
    }

    const handleUploaded = (objId: number, file: File) => {
        if (file) {
            const _files = asset.attachments!.concat({ id: objId, file_name: file.name, file_size: file.size });
            updateAsset({ attachments: _files });
        }
    }

    const handleEnterLoad = (value: number, name: string) => {
        console.log('enter new log value: ', value)
        updateAsset({ [name]: value });

        insertLog({
            value: value 
        });

    }

    const handleCloseScheduling = () => {
        modal.closeModal();
        loadAssetSchedulings(id);
    }

    const initTask = {
        summary: 'Új feladat',
        status: 'TODO', 
        due_date: today(), 
        asset: {
            id: asset.id, 
            name: asset.name
        }, 
    }

    const handleNewTask = () => {
        saveAsset().then((id: number) => {
            id && modal.showModal(<TaskScheduler id={null} init={initTask} onClose={handleCloseScheduling} />);
        });
    }

    const handleNewTag = (name: string) => {
        createGroup(name).then((groupId: number) => {
            const _newGroup = {
                id: groupId,
                title: name
            };

            const _groups = groups ? groups.concat(_newGroup) : [_newGroup];
            updateAsset({ groups: _groups });
        });
    }

    const handleOpenScheduling = (id: number, handleReloadList: Function) => {
        id && modal.showModal(<TaskScheduler id={id} onClose={() => handleCloseScheduling(handleReloadList)} />);
    }

    const handleValidate = () => {
        //validateTask();
    }

    const errorMessages = useMemo(() => {

        const ret = [];
        for (const [key, value] of Object.entries(errors)) {
            if (value) {
                ret.push(value);
            }
        }

        return ret;
    }, [errors]);

    return (
        //{ /*<LoadingOverlay isLoading={isLoading} spinner={<Spinner />}> */ }

        <Dialog 
            header={ <>
                <div className='dlg-type'>{ t('translation:asset.titleDlgType') }</div>
                <EditableTitle name='name' title={name} onChange={handleChange} />
            </> }
            headerRightButtons={<>
                <SaveController t={t} modified={isModified} save={handleSave} />
                <CloseButton onClose={handleClose} />
            </>}
            footer={<>
                <div>
                    { /* <Button onClick={handleSave} variant='primary'>{ t('translation:asset.btnSave') }</Button>
                    <Button onClick={handleValidate}>{'Validálás'}</Button> */}
                </div>
                <Button onClick={handleClose}>{ t('translation:asset.btnCancel') }</Button>
            </>}
            errorMessages={errorMessages}
            onClose={handleClose}>
        
                    <Tabs selected='tab_details'>
                        <Tabs.Tab name='tab_details' title={ t('translation:asset.tabAssetDetails') } icon='th-list'>

                            <div className='row'>
                                <div className='half'>

                                    <Form.Group>
                                        <Form.Label>{ t('translation:asset.lblGroups') }</Form.Label>
                                        <TagsInput name='groups' 
                                            value={groups} 
                                            dataList={assetGroups}
                                            limit={10}
                                            onChange={handleChange}
                                            onNewTag={handleNewTag} />
                                    </Form.Group>

                                    <Tabs selected='tab_comments'>
                                        <Tabs.Tab name='tab_comments' title={ t('translation:asset.tabComments') }>
                                            <TextArea name='comment' value={comment} onChange={handleChange} />
                                        </Tabs.Tab>
                                        <Tabs.Tab name='tab_files' title={ t('translation:asset.tabAttachments') }>
                                            <FileList 
                                                list={attachments} 
                                                onFinished={handleUploaded} />
                                        </Tabs.Tab>
                                    </Tabs>

                                </div>
                                <div className='half'>

                                    <div className='row'>
                                        <div className='half'>
                                            <Form.Group>
                                                <Form.Label>{ t('translation:asset.lblMake') }</Form.Label>
                                                <Text name='make' value={make} onChange={handleChange} />
                                            </Form.Group>
                                        </div>
                                        <div className='half'>
                                            <Form.Group>
                                                <Form.Label>{ t('translation:asset.lblModel') }</Form.Label>
                                                <Text name='model' value={model} onChange={handleChange} />
                                            </Form.Group>
                                        </div>
                                    </div>

                                    <Form.Group>
                                        <Form.Label>{ t('translation:asset.lblInventoryNumber') }</Form.Label>
                                        <Text name='inventory_number' value={inventory_number} onChange={handleChange} />
                                    </Form.Group>
                                </div>
                            </div>
                        </Tabs.Tab>
                        <Tabs.Tab name='tab_tasks' title={ t('translation:asset.tabSchedulingTasks') } icon='tasks'>
                            <div className='row'>
                                <Button variant='primary' onClick={handleNewTask}>{ t('translation:asset.btnNewScheduling') }</Button>
                            </div>
                            <div className='row'>
                                <SchedulingList 
                                    list={schedulings} 
                                    onSelect={handleOpenScheduling} 
                                />
                            </div>
                        </Tabs.Tab>

                        <Tabs.Tab name='tab_logbook' title={ t('translation:asset.tabAssetLogBook') } icon='sticky-note'>
                            <div className='row'>
                                <div className='half'>
                                    <Form.Group>
                                        <Form.Label>{ t('translation:asset.lblLoadValue') }</Form.Label>
                                        <UnitInput name='loadValue' unitName='loadUnit'
                                            value={loadValue} 
                                            units={[
                                                { id: 'KM', title: t('translation:inputs:unitKm') },
                                                { id: 'HOUR', title: t('translation:inputs:unitHours') },
                                            ]}
                                            unit={loadUnit}
                                            onEnter={handleEnterLoad}
                                            onChange={handleChange} />
                                    </Form.Group>
                                </div>
                            </div>
                        </Tabs.Tab>

                    </Tabs>

        
        </Dialog>
    //{ /*</LoadingOverlay> */}
    );
};

export default AssetDetails;