import React, { useState, useRef, useEffect } from "react";

const d = require('debug')('ma');
d.log = console.log.bind(console);

import { CursorPosition, VNode, VDoc, VText, VTag, VDummy } from './nodes';
import VParagraph from "./nodes/VParagraph";


const vdoc = new VDoc();
vdoc.addChild(new VText('Hello, ez'));
vdoc.addChild(new VTag('2021. május 14.'));
vdoc.addChild(new VText('Bello !'));
vdoc.addChild(new VDummy());
vdoc.addChild(new VText('Ez a vége...'));
vdoc.addChild(new VText('Na még egy kicsi!'));
vdoc.addChild(new VText(''));
vdoc.addChild(new VParagraph('Ez már egy szép bekezdés.'));

function printNode(node: Node | null | undefined) {
    if (!node) return '';

    if (node.nodeType === 3) {
        // TEXT_NODE
        return `[#TEXT: ${(node as Text).data}]`;
    }

    if (node.nodeType === 1) {
        // ELEMENT_NODE
        const attr_0 = (node as Element).attributes.getNamedItem('id');
        return `[DIV: ${attr_0?.value}]`;
    }
    return `[${node.nodeName}]`;
}
/*
function findHTMLNode(rootNode: Node, node: Node): Node | undefined {

    console.log(`findHTMLNode: ${rootNode} - ${node}`)

    if (rootNode === node) {
        console.log('foiund node: ')
        return node;
    }

    const childNodes = rootNode.childNodes;
    for (let i = 0; i < childNodes.length; i++) {
        const found = findHTMLNode(childNodes.item(i), node);
        if (found) return found;
    }

    return;
}
*/

function renderEditorContent(node: Node) {
    const newChild = vdoc.render();

    if (node.childNodes.length === 0) {
        node.appendChild(newChild);
    } else {
        const oldChild = node.childNodes.item(0);
        node.replaceChild(newChild, oldChild);
    }
}

// TODO: ezen még sokat kell dolgozni
export default function RichEditor({ content, editable = false } : any) {

    const cursor = useRef<CursorPosition | null>(null);
    const ref = useRef<HTMLDivElement | null>(null);

    const style = {
        backgroundColor: 'cornsilk'
    }

    const handleChange = (e: any) => {
        console.log('change: ', e);
        console.log('target: ', e.target);
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        event.preventDefault();

        const key = event.nativeEvent?.key;

        if (cursor.current) {
            const vnode = cursor.current.vnode;

            var sel = window.getSelection();
            sel!.removeAllRanges();

            const action = vnode.update({
                type: 'KEY_DOWN',
                key: key
            }, cursor.current);

            if (action) {
                console.log('response action: ', action);




            }

            renderEditorContent(ref.current!);
            vnode.setCursorPosition(cursor.current);

        }

    }

    const handleClick = (event: React.MouseEvent) => {
        //console.log('mouse click: ', event.nativeEvent)
    }

    const handleSelectionChange = (event: Event) => {
        
        let selection = document.getSelection();
        if (selection?.rangeCount === 0) return;

        const anchor = selection?.anchorNode;
        const vnode = vdoc.containsNode(anchor!);
        //console.log('VNODE: ', vnode)

        const range = selection!.getRangeAt(0);
        //console.log(`RANGE common: ${printNode(range.commonAncestorContainer)} - ${range.collapsed ? 'CURSOR' : 'RANGE'} - start: ${printNode(range.startContainer)} : ${range.startOffset} - end: ${printNode(range.endContainer)} : ${range.endOffset}`);
        const currentVNode = cursor.current?.vnode;

        if (vnode && range.collapsed) {

            const nextCursor = {
                vnode: vnode as VNode,
                offset: range.endOffset
            };

            if (currentVNode !== vnode) {
                currentVNode && currentVNode.update({ type: 'BLUR' }, null);
                vnode.update({ type: 'FOCUS' }, nextCursor);
            }
            cursor.current = nextCursor;

        } else {
            currentVNode && currentVNode.update({ type: 'BLUR' }, null);
            cursor.current = null;
        }

    }

    useEffect(() => {
        renderEditorContent(ref.current!);
        document.onselectionchange = handleSelectionChange;
    }, []);

    d('render')

    return (<div style={style} 
        ref={ref}
        onInput={handleChange}
        onKeyDown={handleKeyDown}
        onMouseUp={handleClick}
        contentEditable={true}>
    </div>);

}
