import React, { useState, useMemo, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

import { useScheduling } from '../../api/scheduling';
import { usePersonsQuery } from '../../api/person';
import { useAssetsQuery, useAssetAttactments } from '../../api/asset';
import { Dialog, CloseButton, Form, Button, Text, EditableTitle, DropdownList, 
    NumberPicker, DayInput, 
    CalendarInput, TagsInput, TextArea, Tabs, 
    FileList, LoadingOverlay, Spinner,
    RadioInput, RadioButton, Helpy } from '../../components';

const SaveController = ({ modified, save: saveTask, t }: any) => {

    const handleClick = () => {
        saveTask();
    }

    return <div>{ modified == 1 ? 
        <button className='btn btn-small btn-primary' onClick={handleClick}>{ t('translation:details.btnSave') }</button> : modified == 2 ? <span>{ t('translation:details.lblSaved') }&nbsp;</span> : '' }
    </div>;
}

const TaskScheduler = ({ id, init: _init, onClose }: any) => {

    const { t } = useTranslation();
    const { scheduling, 
        initScheduling: init, loadScheduling, update, saveScheduling, 
        isLoading, isModified, errors } = useScheduling();
    const { summary, description, attachments, completion, asset, accountable, 
        trigger_type, start_date, initial_value, recurrence,
        has_recurrence } = scheduling;
    //const [hasRecurrence, setHasRecurrence] = useState<string>('SINGLE');

    useEffect(() => {
        if (id) {
            loadScheduling(id);
        } else if (_init) {
            init(_init);
        }
    }, []);

    const [ personsFilter, setPersonsFilter ] = useState('');
    const { persons } = usePersonsQuery(personsFilter);
    const personsData = useMemo(() => {
        return persons.map((person: any) => ({
            id: person.id,
            title: `${person.family_name} ${person.given_name}`, 
        }))
    }, [persons]);

    const [ assetsFilter, setAssetsFilter ] = useState('');
    const { assets } = useAssetsQuery(assetsFilter);
    const assetsData = useMemo(() => {
        return assets.map((asset: any) => ({
            id: asset.id,
            title: asset.name, 
        }))
    }, [assets]);

    /*
    useEffect(() => {
        const _hasRecurrence = scheduling.recurrence?.freq ? 'RECURRENCE' : 'SINGLE';
        setHasRecurrence(_hasRecurrence);
    }, [scheduling.recurrence?.freq]);*/

    const { attachments: asset_files } = useAssetAttactments(scheduling?.asset?.id);

    const statusesDropdownData = [
        { id: 'TODO', title: t('translation:task.titleTODO') }, 
        { id: 'IN_PROGRESS', title: t('translation:task.titleIN_PROGRESS') }, 
        { id: 'WAITING', title: t('translation:task.titleWAITING') }, 
        { id: 'DONE', title: t('translation:task.titleDONE') }, 
        { id: 'CLOSED', title: t('translation:task.titleCLOSED') }, 
        { id: 'DELETED', title: t('translation:task.titleDELETED') }, 
    ];

    const handleClose = () => {
        onClose && onClose();
    }

    // mapChangeToObject
    const handleChange = (value: any, name: string) => {
        console.log('change value: ', name, value);

        if (name === 'asset') {
            const asset = value[0];
            update({ 'asset': {
                id: asset.id,
                name: asset.title
            } });
            return;
        }

        if (name === 'accountable') {
            const person = value[0];
            update({ 'accountable': {
                id: person.id,
                name: person.title
            } });
            return;
        }

        if (name.substr(0, 11) == 'recurrence.') {
            const _name = name.substr(11);            
            const _recurrence = {
                ...recurrence,
                [_name]: value
            }
            update({ 'recurrence': _recurrence });
            return;
        }

        //if (name === 'trigger_type') return;

        update({ [name]: value });

        // automatic fillings
        if (name === 'status' && value === 'DONE') {

        }


    }

    const handleSave = () => {
        /*if (validateTask()) {
            saveTask();
        }*/

        saveScheduling();
    }

    const handleFinished = () => {
        console.log('finished upload');
        /*objectId.current && updateStatus(objectId.current, 'ACTIVE').then((resp: any) => {
            console.log('updated binary object')
            setProgress(null);
        }).catch((error: any) => {
            console.error(error);
        });*/
    }

    const handleValidate = () => {
        //validateTask();
    }

    const errorMessages = useMemo(() => {

        const ret = [];
        for (const [key, value] of Object.entries(errors)) {
            if (value) {
                ret.push(value);
            }
        }

        return ret;
    }, [errors]);
    

    return (
    //{ /*<LoadingOverlay isLoading={isLoading} spinner={<Spinner/>}> */}
        <Dialog 
            header={<>
                <div className='dlg-type'>{ t('translation:scheduling.titleDlgType') }</div>
                <EditableTitle name='summary' title={ summary } onChange={handleChange} />
            </>}
            headerRightButtons={<>
                <SaveController t={t} modified={isModified} save={handleSave} />
                <CloseButton onClose={handleClose} />
            </>}
            footer={<>
                <div>
                    { /* <Button onClick={handleSave} variant='primary'>{ t('translation:task.btnSave') }</Button>
                    <Button onClick={handleValidate}>{'Validálás'}</Button> */}
                </div>
                <Button onClick={handleClose}>{ t('translation:task.btnCancel') }</Button>
            </>}
            errorMessages={errorMessages}
            onClose={handleClose}>
                { /* <Form> */ }
                    <Tabs selected='task_definition'>
                        <Tabs.Tab name='task_definition' title={ t('translation:task.tabTaskPlanning') } icon='pencil-ruler'>

                            <div className='row'>
                                <div className='w60'>
                                    <Form.Group>
                                        <Form.Label>{ t('translation:task.lblDescription') }</Form.Label>
                                        <TextArea name='description' value={description} onChange={handleChange} />
                                    </Form.Group>
                                    <Tabs selected='attachments'>
                                        <Tabs.Tab name='asset_files' disabled={asset_files.length === 0} title={ t('translation:task.tabAssetFiles') }>
                                            <FileList 
                                                list={asset_files} 
                                                readOnly={true} />
                                        </Tabs.Tab>
                                        <Tabs.Tab name='attachments' title={ t('translation:task.tabAttachments') }>
                                            <FileList 
                                                list={attachments} 
                                                onFinished={handleFinished} />
                                        </Tabs.Tab>
                                     { /* <Tabs.Tab name='document_generation' title={ t('translation:task.tabDocsGeneration') }>
                                            <p className='desc'>Válassza ki, hogy milyen dokumentumot szeretne generálni:</p>
                                        </Tabs.Tab> */}
                                    </Tabs>
                                </div>
                                <div className='w40'>
                                    <Form.Group>
                                        <Form.Label>{ t('translation:scheduling.lblCompletion') }&nbsp;<Helpy content='A feladat kiírása után ennyi nappal lesz annak határideje.' /></Form.Label>
                                        <NumberPicker name='completion' value={completion} onChange={handleChange} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>{ t('translation:task.lblAsset') }</Form.Label>
                                        <TagsInput name='asset' 
                                            value={asset ? [{ id: asset.id, title: asset.name }] : []} dataList={assetsData} 
                                            onChange={handleChange}
                                            onFilter={((filter: string) => setAssetsFilter(filter))} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>{ t('translation:task.lblAccountable') }</Form.Label>
                                        <TagsInput name='accountable' 
                                            value={accountable ? [{ id: accountable.id, title: accountable.name }] : []} dataList={personsData} 
                                            onChange={handleChange}
                                            onFilter={((filter: string) => setPersonsFilter(filter))} />
                                    </Form.Group>
                                </div>
                            </div>
                        </Tabs.Tab>

                        <Tabs.Tab name='task_scheduling' title={ t('translation:task.tabTaskScheduling') } icon='clock'>
                            <div className='row'>
                                <div className='w40'>
                                    <Form.Group>
                                        <Form.Label>{ 'Ütemezés típusa' }</Form.Label>
                                        <RadioInput name='trigger_type' value={trigger_type} onChange={handleChange}>
                                            <RadioButton title='Idő alapú' value='TIME' />
                                            <RadioButton title='Használat alapú' value='METER' />
                                        </RadioInput>
                                    </Form.Group>
                                </div>
                                <div className='w60'>
                                    <Form.Group>
                                        <Form.Label>{ t('translation:task.lblRecurring') }</Form.Label>
                                        <DropdownList name='has_recurrence' 
                                            value={has_recurrence}
                                            onChange={handleChange}
                                            dataList={
                                                [
                                                    { id: 'SINGLE', title: 'Egyszeri feladat' }, 
                                                    { id: 'RECURRENCE', title: 'Ismétlődő feladat' }, 
                                                ]
                                            } 
                                            />
                                    </Form.Group>
                                    { has_recurrence === 'SINGLE' ? <>
                                    { (trigger_type === 'TIME') && <> 
                                        <Form.Group>
                                            <Form.Label>{ 'Létrehozás napja' }</Form.Label>
                                            <CalendarInput name='start_date' value={start_date} onChange={handleChange} />
                                        </Form.Group>
                                    </> }
                                    { (trigger_type === 'METER') && <> 
                                        <Form.Group>
                                            <Form.Label>{ 'Határérték' }</Form.Label>
                                            <Text name='initial_value' value={initial_value} onChange={handleChange} />
                                        </Form.Group>
                                    </> }
                                    </> : <> 
                                        { (trigger_type === 'TIME') && <>
                                            <Form.Group>
                                                <Form.Label>{ 'Az ismétlődés kezdete' }</Form.Label>
                                                <CalendarInput name='start_date' value={start_date} onChange={handleChange} />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>{ 'Gyakoriság' }</Form.Label>
                                                <div className='row'>
                                                    <NumberPicker name='recurrence.interval' min={1} max={36} value={recurrence?.interval} onChange={handleChange} />
                                                    <DropdownList name='recurrence.freq' 
                                                        value={recurrence?.freq}
                                                        dataList={[
                                                            { id: 'DAY', title: t('translation:recurring.lblDay') }, 
                                                            { id: 'WEEK', title: t('translation:recurring.lblWeek') }, 
                                                            { id: 'MONTH', title: t('translation:recurring.lblMonth') }, 
                                                        ]} 
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </Form.Group>
                                            { (recurrence?.freq === 'WEEK') && <>
                                                <Form.Group>
                                                    <Form.Label>{ 'A hét napja' }</Form.Label>
                                                    <DayInput name='recurrence.by_day' value={recurrence?.by_day} onChange={handleChange} />
                                                </Form.Group>
                                            </> }
                                            { (recurrence?.freq === 'MONTH') && <>
                                                <div className='w40'>
                                                    <Form.Group>
                                                        <Form.Label>{ 'A hónap napja' }</Form.Label>
                                                        <NumberPicker name='recurrence.by_monthday' min={1} max={31} value={recurrence?.by_monthday} onChange={handleChange} />
                                                    </Form.Group>
                                                </div>
                                            </> }
                                        </> }
                                        { (trigger_type === 'METER') && <>
                                            <Form.Group>
                                                <Form.Label>{ 'A számláló kezdete' }</Form.Label>
                                                <Text name='initial_value' value={initial_value} onChange={handleChange} />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>{ 'Gyakoriság' }</Form.Label>
                                                <Text name='recurrence.incremental_value' value={recurrence?.incremental_value} onChange={handleChange} />
                                            </Form.Group>
                                        </> }

                                    </> }
                                </div>
                            </div>
                            <div className='row'>
                            </div>
                        </Tabs.Tab>

                    </Tabs>

                { /* </Form> */ }
        </Dialog>

    //{/*</LoadingOverlay> */}
    
    );
};

export default TaskScheduler;