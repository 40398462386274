import React, { useState } from 'react';
import { Handle, Position } from 'react-flow-renderer';

import { Icon } from '../../components';

const EditableNodeText = ({ text, id, onChange }: any) => {

    const [editable, setEditable] = useState(false);

    const handleDoubleClick = (e: any) => {
        e.stopPropagation();
        console.log('handleDoubleClick');
        !editable && setEditable(true);
    }

    const handleBlur = (e: any) => {
        const innerText = e.target?.innerText;
        setEditable(false);
        onChange && onChange(innerText, id);
    }

    const handleChangeInput = (e: any) => {
        //const innerText = e.target?.innerText;
        //console.log('handleChangeInput: ', e.target?.innerText)
    }

    const handleKeyDown = (e: any) => {
        const keyCode = e.keyCode;

        if (keyCode === 13) {
            e.preventDefault();
            const innerText = e.target?.innerText;
            setEditable(false);
            onChange && onChange(innerText, id);
        } else if (keyCode === 27) {
            setEditable(false);
            e.target.innerHTML = text;
        }

    }

    return <div className='text nodrag' 
        onDoubleClick={handleDoubleClick}
        contentEditable={editable}
        onInput={handleChangeInput}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}>{text}</div>;
}

export const Uzemelteto = ({ id, data }: any) => {

    const handleChangeNodeText = (text: string) => {
        console.log(text);
    }

    return <>
        <Handle
            type="target"
            position={Position.Top}
            id="t1"
            style={{ top: '0%', borderRadius: 0 }}
        />
        <div className='node uzemelteto'>
            <EditableNodeText text={data.text} id={id} onChange={data.onChange} />
        </div>
        <Handle
            type="source"
            position={Position.Bottom}
            id="s1"
            style={{ top: '90%', borderRadius: 0 }}
        />
        <Handle
            type="source"
            position={Position.Right}
            id="d1"
            style={{ top: '50%', background: '#555' }}
        />
    </>
};

export const Hatosag = ({ data }: any) => {

    const handleClick = (e: any) => {
        console.log('Hatosag clicked')
    }

    return <>
        <Handle
            type="target"
            position={Position.Top}
            id="t1"
            style={{ top: '0%', borderRadius: 0 }}
        />      
        <div className='node hatosag' onClick={handleClick}>
            <div className='text'>{data.text}</div>
        </div>
        <Handle
            type="source"
            position={Position.Bottom}
            id="s1"
            style={{ top: '90%', borderRadius: 0 }}
        />
        <Handle
            type="source"
            position={Position.Right}
            id="d1"
            style={{ top: 10, background: '#555' }}
        />
    </>
};

export const Dokumentum = ({ data }: any) => {

    const handleClick = (e: any) => {
        console.log('Dokumentum clicked')
    }

    return <>
      <Handle
            type="target"
            position={Position.Left}
            id="t1"
            style={{ top: '50%', borderRadius: 0 }}
      />      
    <div className='node dokumentum' onClick={handleClick}>
        <div className='text'>
            <Icon name='file' />
            { data.text }
        </div>
    </div>
    </>
};
