import React, { useState, useRef, useEffect, useMemo } from "react";

interface DataTableProps {
    rows: Array<DataTableRowData>;
    columns: Array<DataTableColumnProps>;
    groupBy?: string;
    onSelectRow?: Function;
};

export interface DataTableColumnProps {
    name: string;
    title: string;
    width?: number;
    type?: DataTableCellType;
    render?: (cell: DataTableCellData, row: DataTableRowData) => JSX.Element;
};

export enum DataTableCellType {
    String,
    Number,
    Labels,
    Tasks,
    Date
};

export type DataTableCellData = string | object | Array<string>;
export type DataTableGroupRow = string;
export interface DataTableRowType {
    [key: string]: DataTableCellData;
};

export type DataTableRowData = Array<DataTableCellData> | DataTableGroupRow | DataTableRowType;


const renderHeaderCell = (column: DataTableColumnProps): JSX.Element => {
    if (typeof column === 'string') return <th scope="col">{column}</th>

    return <th scope="col">{column.title}</th>
}

const renderCell = (cell: DataTableCellData, column: DataTableColumnProps, row: DataTableRowData): JSX.Element => {

    //if (!cell) return <td></td>;

    if (typeof column.render === 'function') {
        return column.render(cell, row);
    }

    switch (column.type) {
        case DataTableCellType.String:
            return <td>{ cell }</td>

        case DataTableCellType.Labels:

            if (Array.isArray(cell)) {
                return <td>{ (cell as Array<string>).map((label: string) => <span className='tag'>{ label }</span>) }</td>
            } else if (typeof cell === 'string') {
                return <td><span className='tag'>{ cell }</span></td>
            } else {
                return <td></td>
            }
            
        default:
            return <td>{ cell }</td>
    }

}

const TableGrouping = ({ title, colNums }: any) => {
    return (<tr>
        <th colSpan={colNums}>{ title }</th>
    </tr>);
}

function renderRow(row: DataTableRowData, index: number, columns: Array<DataTableColumnProps>, onSelectRow: Function): JSX.Element {

    let cells: Array<any>;

    if (Array.isArray(row)) {

        cells = (row as Array<DataTableCellData>).map((cell: DataTableCellData, index: number) => renderCell(cell, columns[index], row));

    } else if (typeof row === 'object' && row !== null) {

        cells = columns.map((column: DataTableColumnProps) => renderCell(row[column.name], column, row));

    } else if (typeof row === 'string') {
        return <TableGrouping title={row as DataTableGroupRow} colNums={ columns.length } />
    } else return <></>

    return <tr onClick={() => onSelectRow && onSelectRow(row, index)}>{ cells }</tr>;
}

export const DataTable = ({ rows, columns, onSelectRow = () => {} }: DataTableProps) => {

    return (<table className='ma-table'>
        <thead>
            <tr>
                { columns.map((column: any) => renderHeaderCell(column)) }
            </tr>
        </thead>
        <tbody>
            { rows.map((row: DataTableRowData, index: number) => renderRow(row, index, columns, onSelectRow)) }
        </tbody>
    </table>);
}
