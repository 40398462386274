import { useState, useRef, useEffect } from "react";

import { startUpload, uploadToS3, updateStatus, getDocuments, getDownloadURL } from './binobject';

export const useFile = () => {

    const [progress, setProgress] = useState<number | null>(null);
    const cancelToken = useRef<Function>();
    const objectId = useRef<number>();

    const _uploadToS3 = (files: FileList, onFinished: Function) => {
        const file = files && files[0];

        if (file) {
            startUpload(file.name, file.type).then((data: any) => {
                objectId.current = data.id;
    
                return uploadToS3(file, data.url, data.fields, _handleProgress, (token) => {
                    cancelToken.current = token;
                });
    
            }).then((resp: any) => {
                if (resp !== undefined) {
                    // there was not cancel
                    _handleFinished();
                    return onFinished(objectId.current, file);
                }
            }).catch((error: any) => {
                // hiba a feltöltésben vagy az elkezdésében
                console.error(error);
            });
        }

    }

    const _handleProgress = (data: any) => {
        const percent = Math.floor((100 * data.loaded) / data.total); 
        setProgress(percent);
    }

    const _handleCancel = () => {
        if (cancelToken.current) {
            cancelToken.current();
            setProgress(null);
        }
    }

    const _handleFinished = () => {
        objectId.current && updateStatus(objectId.current, 'ACTIVE').then((resp: any) => {
            console.log('updated binary object')
            setProgress(null);
        }).catch((error: any) => {
            console.error(error);
        });
    }

    const _getDownloadURL = (id: string) => {
        return getDownloadURL(id);
    }

    return {
        uploadFile: _uploadToS3,
        uploadProgress: progress, 
        cancelUpload: _handleCancel, 
        getDownloadURL: _getDownloadURL, 
    }
}

interface FileType {
    id: number;
    fileName: string;
}

export const useFiles = () => {

    const [isLoading, setLoading] = useState(false);
    const [files, setFiles] = useState<Array<FileType>>([]);

    const _loadFiles = () => {
        setLoading(true);

        getDocuments().then(files => {
            setLoading(false);
            setFiles(files);
        }).catch(error => {
            setLoading(false);
            console.log('error: ', error)
        });

    }

    useEffect(() => {
        _loadFiles();
    }, []);

    return {
        files, 
        isLoading, 
    }

}
