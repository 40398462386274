
import React, { useState, useRef, useEffect } from "react";
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";

export default function NavbarLeft({ children, to, onChange }: any) {

    let history = useHistory();

    const onSelectMenuItem = function(to: string) {
        history.push(to);
        onChange && onChange(to);
    }

    return <nav className='left'>
        <ul className='nav-left'>
            <li className='nav-logo'>
                <img src='images/logo.png' width='178' />
            </li>

            { React.Children.map(children, child => {

                return React.cloneElement(child, {
                    onClick: onSelectMenuItem,
                    active: to === child.props.to
                });

            })}

        </ul>
    </nav>
}

NavbarLeft.propTypes = {
    to: PropTypes.string, 
    onChange: PropTypes.func,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
} 
