import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Canceler } from 'axios';

import { useAPI } from '../api';
import { useUser } from '../providers/UserProvider';
import { useSettings } from '../api/settings';

import { Page, PageContent, Form, Text, Button, Helpy } from '../components';

const initSettings = {
    email_before: '', 
    before_duedate: '', 
    email_after: '', 
    after_duedate: '', 
}

export default function RulesPage({ location }: any) {

    const { t } = useTranslation();
    const user = useUser();

    const [form, setForm] = useState<any>(initSettings);
    const cancelRef = useRef<Canceler>();
    const { fetchData, updateData } = useAPI({ cancelRef, onError: null, onForbidden: null });

    useEffect(() => {
        fetchData(`/api/user`)
        .then((resp: any) => {
            const settings = resp.data;
            if (settings) {
                console.log('form: ', form)
                console.log('tárol: ', settings)
                setForm(settings);
            }
        })
        .catch((error: any) => {});
    }, []);

    const handleChange = (value: any, name: string) => {
        console.log(`change: ${name} = ${value}`)

        const _form = {
            ...form,
            [name]: value
        };
        setForm(_form);
    }

    const handleSave = () => {
        updateData(`/api/user/${user.userId}`, {
            email_before: form.email_before,
            before_duedate: form.before_duedate,
            email_after: form.email_after,
            after_duedate: form.after_duedate,
        })
        .then((resp: any) => {})
        .catch((error: any) => {});
    }

    return (<Page title={ t('translation:pages.rules') } to={ location.pathname }>
        <PageContent>
            <div className='row'>
                <div className='half'>
                    <Form.Group>
                        <Form.Label>{ 'Értesítési e-mail cím' }</Form.Label>
                        <Text name='email_before' value={form.email_before} onChange={handleChange} />
                    </Form.Group>
                </div>
                <div className='half'>
                    <Form.Group>
                        <Form.Label>{ 'Mennyi nappal a határidő előtt' }<Helpy content={ <>Hány nappal a határidő lejárta <u>előtt</u> küldjön egy figyelmeztető email-t.</> } /></Form.Label>
                        <Text name='before_duedate' value={form.before_duedate} onChange={handleChange} />
                    </Form.Group>
                </div>
            </div>
            <div className='row'>
                <div className='half'>
                    <Form.Group>
                        <Form.Label>{ 'Értesítési e-mail cím' }</Form.Label>
                        <Text name='email_after' value={form.email_after} onChange={handleChange} />
                    </Form.Group>
                </div>
                <div className='half'>
                    <Form.Group>
                        <Form.Label>{ 'Mennyi nappal a határidő lejárta után' }<Helpy content={ <>Hány nappal a határidő lejárta <u>után</u> küldjön egy figyelmeztető email-t.</>} /></Form.Label>
                        <Text name='after_duedate' value={form.after_duedate} onChange={handleChange} />
                    </Form.Group>
                </div>
            </div>
            <div className='row'>
                <Button variant='primary' onClick={handleSave}>Mentés</Button>
            </div>
        </PageContent>
    </Page>);

}

