import React from 'react';

const MALogo = () => {
  return (<svg 
    viewBox='0 0 1000 1000' xmlns="http://www.w3.org/2000/svg">

    <g id="g68" transform="matrix(0.35277777,0,0,-0.35277777,133.84424,155.32337) ">
        <path d="m-209.76378,439.37009l-49.4,0.001l-49.399,0.001c-38.439,0 -69.601,-31.16 -69.602,-69.6l-0.001,-49.399l0,-49.399c-0.001,-38.44 31.159,-69.602 69.599,-69.603l49.399,0l49.4,-0.001c38.44,0 69.601,31.16 69.602,69.6l0.001,49.399l0.001,49.399c0,38.44 -31.161,69.602 -69.6,69.602" 
        id="path70" fill-rule="nonzero" fill="#24255d"/>
    </g>
    <g id="g72" transform="matrix(0.35277777,0,0,-0.35277777,116.45958,190.13037) ">
        <path d="m-209.76378,439.37009c33.514,-0.073 52.422,-14.97 55.531,-34.958c3.201,-20.588 -10.523,-51.389 -25.423,-56.55c-3.933,-1.363 -2.795,9.868 -3.181,25.286c-0.386,15.419 0.487,25.176 -1.098,27.903c-1.574,2.709 -13.11,11.257 -25.952,11.276c-12.84,-0.019 -24.377,-8.566 -25.952,-11.275c-1.584,-2.727 -0.712,-12.485 -1.098,-27.903c-0.387,-15.418 0.751,-26.649 -3.182,-25.286c-14.899,5.162 -28.624,35.963 -25.421,56.55c3.11,19.989 22.018,34.885 55.532,34.957l0.119,0l0.125,0z" 
        id="path74" fill-rule="nonzero" fill="#57eaab"/>
    </g>
    <g id="g76" transform="matrix(0.35277777,0,0,-0.35277777,123.11965,178.95931) ">
        <path d="m-209.76378,439.37009c0,10.494 -8.507,19 -19,19c-10.494,0 -19,-8.506 -19,-18.999c0,-10.494 8.506,-19.001 18.999,-19.001c10.493,0 19,8.507 19.001,19" 
        id="path78" fill-rule="nonzero" fill="#ffffff"/>
    </g>

    </svg>)
}

export default MALogo;