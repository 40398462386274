
import { VNode } from './types';
import { h } from './utils'

export default class VDummy implements VNode {
    private _rendered: Node;

    constructor() {
    }

    public render() {

        const span = h('span', { 
            contenteditable: false,
            style: 'cursor: pointer; background: lightblue; border: 1px solid black; padding: 5px 20px;' 
        }, h('b', null, 'Dummy'));
        
        this._rendered = span;
        return this._rendered;
    }

    public containsNode(node: Node) {
        return (this._rendered === node) ? this : false;
    }

    public update(action: any, cursor: any) {}

    public setCursorPosition(cursor: any) {}

}

