import React, { useEffect, useRef, useMemo } from "react";

const defaultStrengths = [
    { level: 33, title: 'weak', color: '#fe4758' }, 
    { level: 66, title: 'medium', color: '#fea500' }, 
    { level: 100, title: 'strong', color: '#23ac5e' }, 
];

const getColor = (value: number, strengths: Array<any>) => {
    for (const item of strengths) {
        if (value <= item.level) return [item.title, item.color];
    }

    return ['n/a', 'grey'];
}

export const PasswordStrength = ({ name, value = 0, strengths = defaultStrengths, autoFocus = false, 
    onChange = () => {}, 
    inputRef, ...props }: any) => {

    const ref = inputRef || useRef<HTMLInputElement>(null);

    useEffect(() => {
        autoFocus && setTimeout(() => ref && ref.current?.focus(), 100);
    }, [autoFocus])

    const progress = useMemo(() => {
        if (typeof value !== 'number') return 0;
        if (value > 100) return 100;
        if (value < 0) return 0;

        return value;
    }, [value]);

    const [title, bgColor] = getColor(progress, strengths);
    const style = {
        width: `${progress}%`, 
        backgroundColor: bgColor
    }

    //const title = 'közepes';

    return <div className='progress'>
        <div className='progress-bar' style={style}></div>
        <div className='title'>{title}</div>
    </div>

}