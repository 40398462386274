import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { useUser } from '../../providers/UserProvider';

import { logout as remoteLogout } from '../../api';
import { useOnClickOutside } from '../../api/hooks';
import { Avatar } from '../index';

const NavbarTopItem = ({ title, icon, onClick = () => {} }: any) => {

    return (<li className='nav-item' onClick={onClick}>
        { icon ? <i className={`las la-${icon} menu-size`}></i> : title ? <span>{title}</span> : '' }
    </li>);

}
const TopmenuItem = ({ title, icon, onClick = () => {} }: any) => {

    return (<li onClick={onClick}>
        { icon ? <><i className={`las la-${icon} button-size`}></i>&nbsp;&nbsp;</> : '' }
        { title }
    </li>);
}

export default function NavbarTop({ title }: any) {

    const { logout, avatarURL } = useUser();
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);
    const history = useHistory();

    useOnClickOutside(ref, () => {
        open && setOpen(false);
    });

    const changeLanguage = (langCode: string) => {
        i18n.changeLanguage(langCode);
    }

    const handleShowMenu = () => {
        setOpen(prev => !prev);
    }

    const handleClickMenu = (name: string) => {
        console.log('click menu: ', name)

        if (name === 'usersettings') {
            history.push(name);
            return;
        }

        if (name === 'logout') {
            remoteLogout().then(() => {
                logout();
            }).catch((err: any) => {
                console.error(err);
            });
            return;
        }


    }

    return (<nav className='top'>
        <span className='title'>{ title }</span>

        <div>
        </div>

        <ul className='nav-top'>
            <li className='nav-item' onClick={() => changeLanguage('hu-HU')}>
                <span>HUN</span>
            </li>

            <li className='nav-item' onClick={() => changeLanguage('en-US')}>
                <span>ENG</span>
            </li>

            <NavbarTopItem icon='bell' />
            <NavbarTopItem icon='comments' />

            <li className='nav-item' onClick={() => handleShowMenu()}>
                <div className='dropdown'>
                    {/* <img className='circular small' src={avatarURL} /> */}
                    <Avatar />
                    <div ref={ref} className={`dropdown-menu right ${open ? 'show' : ''}`}>
                    <ul>
                        <TopmenuItem title='Beállítások' icon='cog' onClick={() => handleClickMenu('usersettings')} />
                        <TopmenuItem title='Kilépés' icon='sign-out-alt' onClick={() => handleClickMenu('logout')} />
                    </ul>
                    </div>
                </div>
            </li>

        </ul>
    </nav>);
}

NavbarTop.propTypes = {
    title: PropTypes.string.isRequired
} 
