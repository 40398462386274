import React, { createContext, useContext, useState } from 'react';

const TOAST_TIMEOUT = 2000;

type ToastContextType = {
    showToast: (msg: string) => void;
}

export const ToastContext = createContext<ToastContextType>({ showToast: () => {} });

export const useToast = () => useContext(ToastContext);

export const ToastProvider = (props: any) => {

    const [toasts, setToasts] = useState<Array<string>>([]);

    const showToast = (message: string) => {

        setToasts((prev: Array<string>) => {
            return [...prev, message];
        });

        setTimeout(() => {
            setToasts((prev: Array<string>) => {
                return prev.slice(1);
            });
    
        }, TOAST_TIMEOUT);

    }

    return (<ToastContext.Provider value={{ showToast }} >
        <div className='global-toast-list'>
            { toasts.map((toast: string) => <div className='toast-message'>{ toast }</div>) }
        </div>
        { props.children }
    </ToastContext.Provider>);
};
