import React, { createContext, useContext, useState } from 'react';

type UserContextType = {
    userId?: number;
    personId?: number;
    loginName?: string;
    emailAddress: string;
    fullName: string;
    avatarURL: string;
    language: string;
    setUser: Function;
    logout: Function;
}

const defaultValue: UserContextType = {
    userId: undefined,
    personId: undefined, 
    loginName: undefined,
    emailAddress: '',
    fullName: '',
    avatarURL: '', 
    language: 'hu-HU', 
    setUser: () => {},
    logout: () => {}
}

export const UserContext = createContext<UserContextType>(defaultValue);

export const useUser = () => useContext(UserContext);

export const UserProvider = (props: any) => {

    const [userData, setUserData] = useState(defaultValue);

    const setUser = (data: any) => {
        setUserData(data);
    }

    const logout = () => {
        setUserData({ ...userData, userId: undefined, loginName: undefined });
    }

    return (<UserContext.Provider value={{ ...userData, setUser, logout }} >
        { props.children }
    </UserContext.Provider>);

}
