
import { VNode } from './types';
import { setCaret, isEditorChar, h } from './utils'

export default class VParagraph implements VNode {
    private _text: string;
    
    private _rendered: Node;
    private _renderedText: Node;

    private _shouldRender: boolean = true;
    private _hasFocus: boolean = false;

    constructor(text: string) {
        this._text = text;
    }

    public render() {
        if (this._shouldRender) {

            // TODO: nem kellene mindig lerenderelni az összes elemet
            const par = document.createElement('p');
            this._renderedText = document.createTextNode(this._text);
            par.appendChild(this._renderedText);
            this._rendered = par;

            this._shouldRender = false;
        }

        return this._rendered;
    }

    public containsNode(node: Node) {
        return (this._renderedText === node) ? this : false;
    }

    public update(action: any, cursor: any) {
        if (!action) return;

        switch (action.type) {
            case 'FOCUS': 
                this._hasFocus = true;
                console.log('VParagraph has focus');
                break;
            case 'BLUR': 
                this._hasFocus = false;
                console.log('VParagraph lost focus');
                break;

            case 'KEY_DOWN': 
                if (isEditorChar(action.key)) {
                    this._text = this._text.splice(cursor.offset, 0, action.key);
                    this._shouldRender = true;
                    cursor.offset = cursor.offset + 1;
                } else if (action.key === 'Backspace') {
                    if (cursor.offset > 0) {
                        this._text = this._text.splice(cursor.offset - 1, 1, '');
                        this._shouldRender = true;
                        cursor.offset = cursor.offset - 1;
                    } else return action;
                } else if (action.key === 'Enter') {
                    return {
                        type: 'APPEND_NEW'
                    }
                } else if (action.key === 'ArrowLeft' && cursor.offset > 0) {
                    cursor.offset = cursor.offset - 1;
                } else if (action.key === 'ArrowRight' && cursor.offset < this._text.length) {
                    cursor.offset = cursor.offset + 1;
                } else return action;

                break;

        }

    }

    public setCursorPosition(cursor: any) {
        setCaret(this._renderedText, cursor.offset);
    }

}
