
import { VNode } from './types';


export default class VDoc implements VNode {
    private _children: Array<VNode> = [];
    private _rendered: Node;

    public addChild(child: VNode) {
        this._children.push(child);
    }

    public render() {

        const div = document.createElement('div');
        div.setAttribute('id', 'vdoc');

        this._children.forEach((child: VNode) => {
            div.appendChild(child.render() as Node);
        });

        this._rendered = div;
        return this._rendered;
    }

    public containsNode(node: Node): VNode | false {
        if (this._rendered === node) return this;

        for(let i = 0; i < this._children.length; i++) {
            const vchild = this._children[i];
            if (vchild.containsNode(node)) return vchild;
        }

        return false;
    }

    public update(action: any, cursor: any) {}

    public setCursorPosition(cursor: any) {}

}
