import React, { useState, useEffect, useRef, RefObject } from "react";

import { useTranslation } from 'react-i18next';
import { useOnClickOutside } from '../api/hooks';
import { thisWeek, thisDateOfMonth, ordinalNumber } from '../utils';

import { RadioInput, RadioPane, NumberPicker, Form, DropdownList } from './index';

interface RecurringType {
    day?: number;

    weekday?: number;
    week?: number; 

    week_in_month?: number;
    day_in_month?: number;
    month?: number;
}

interface ReccurringStateType {
    freq: string;
    interval: number;

    weekday?: number;
    
    in_month?: string;
    day_in_month?: number;
    week_in_month?: number;
}

const codes = [
    "monday",
    "tuesday",
    "wednesday", 
    "thursday",
    "friday",
    "saturday",
    "sunday"
];

const getShortWeekDay = (code: string) => {
    const { t } = useTranslation();
    return t(`translation:abbreviatons.days.${code}`);
}

const DayRadio = ({ dayName, checked = false, onClick = () => {} }: any) => {
    const className = `day-radio ${checked ? 'active' : ''}`;

    return <span onClick={onClick}><div className={className}>
        { getShortWeekDay(dayName) }
    </div></span>
}

const DaySelector = ({ name, value, onChange = () => {} }: any) => {

    return <div className='day-selector'>
        { [1,2,3,4,5,6,7].map((item: number) => <DayRadio dayName={codes[item - 1]} checked={value === item} onClick={() => onChange(item, name)} />) }
    </div>
}

const describeRecurring = (rec: RecurringType): string => {
    const { t } = useTranslation();

    if (rec.day) {
        return rec.day === 1 ? t('translation:recurring.txtEveryDay') : t('translation:recurring.txtEveryNthDay', { day: rec.day });
    }
    
    if (rec.weekday && !rec.month) {
        const weekdayName = t(`translation:calendar.${codes[rec.weekday - 1]}`);
        if (rec.week) {
            return t('translation:recurring.txtNthWeekWeekday', { weekday: weekdayName, week: rec.week });
        } else {
            return t('translation:recurring.txtWeeklyWeekday', { weekday: weekdayName });
        }
    }

    if (rec.month) {
        if (rec.day_in_month) {
            return rec.month === 1 ? t('translation:recurring.txtMonthlyOnDay', { day: rec.day_in_month }) : 
                t('translation:recurring.txtNthMonthlyOnDay', { month: rec.month, day: rec.day_in_month });

        } else if (rec.weekday && rec.week_in_month) {
            const weekdayName = t(`translation:calendar.${codes[rec.weekday - 1]}`);

            return rec.month === 1 ? t('translation:recurring.txtMonthlyOnWeekDay', { weekday: weekdayName, num: ordinalNumber(rec.week_in_month, t) }) : 
                t('translation:recurring.txtNthMonthlyOnWeekDay', { month: rec.month, weekday: weekdayName, num: ordinalNumber(rec.week_in_month, t) });
        }
    }

    return '';
}

const mapRecurringToState = (recurring: RecurringType): ReccurringStateType => {

    //console.log('mapToState: ', recurring)

    if (recurring.day) return {
        freq: 'DAY',
        interval: recurring.day,
    }

    if (recurring.week) return {
        freq: 'WEEK',
        interval: recurring.week,
        weekday: recurring.weekday
    }

    if (recurring.month) {
        if (recurring.day_in_month) {
            return {
                freq: 'MONTH',
                interval: recurring.month,
                in_month: 'day_in_month', 
                week_in_month: thisWeek(), 
                day_in_month: recurring.day_in_month
            }
        }

        if (recurring.week_in_month && recurring.weekday) {
            const thisDate = (new Date()).getDate() || 1;
            return {
                freq: 'MONTH',
                interval: recurring.month,
                in_month: 'week_in_month', 
                week_in_month: recurring.week_in_month,
                weekday: recurring.weekday,
                day_in_month: thisDate
            }
        }
    }

    return {
        freq: 'DAY',
        interval: 1, 
    }
}

const mapStateToRecurring = (state: ReccurringStateType) => {

    const thisDay = (new Date()).getDay() || 7;

    switch (state.freq) {
        case 'DAY':
            return { day: state.interval }
        case 'WEEK':
            return { week: state.interval, weekday: state.weekday || thisDay }
        case 'MONTH':
            const thisDate = (new Date()).getDate() || 1;
            if (!state.in_month) state.in_month = 'day_in_month';

            if (state.in_month === 'day_in_month') {
                return { month: state.interval, day_in_month: state.day_in_month || thisDate }
            }

            return { month: state.interval, 
                week_in_month: state.week_in_month || 1, 
                weekday: state.weekday || thisDay }
    }

    return { day: 1 }
}

const mapChangeToState = (value: any, name: string, prev: ReccurringStateType) => {

    if (name === 'day_in_month') {
        return { ...prev, in_month: 'day_in_month', [name]: value };
    } else if (name === 'weekday' && prev.freq === 'MONTH') {
        return { ...prev, in_month: 'week_in_month', [name]: value };
    }

    return { ...prev, [name]: value };
}

const RecurringDropdown = React.forwardRef<HTMLDivElement, any>(({ open, recurring = {}, onChange = () => {} }: any, ref: React.ForwardedRef<HTMLDivElement>) => {

    const { t } = useTranslation();

    const state = mapRecurringToState(recurring);
    const { freq, interval, weekday, in_month, week_in_month, day_in_month } = state;

    const handleChange = (value: any, name: string) => {
        const nextState = mapChangeToState(value, name, state);
        const recurring = mapStateToRecurring(nextState);
        onChange(recurring);
    }

    return (<div ref={ref} className={`dropdown-calendar ${open ? 'show' : ''}`}>
        <div className='calendar-header'>
            <div className='header-title'>{ describeRecurring(recurring) }</div>
        </div>
        <div className='calendar-content'>

            <Form>
                <Form.Group>
                    <Form.Label>{ t('translation:recurring.lblRepeatEvery') }</Form.Label>
                    <div className='row'>
                        <NumberPicker name='every' min={1} max={36} value={interval} onChange={handleChange} />
                        <DropdownList name='period' 
                            value={freq}
                            dataList={[
                                { id: 'DAY', title: t('translation:recurring.lblDay') }, 
                                { id: 'WEEK', title: t('translation:recurring.lblWeek') }, 
                                { id: 'MONTH', title: t('translation:recurring.lblMonth') }, 
                            ]} 
                            onChange={handleChange}
                        />
                    </div>
                </Form.Group>
                { freq === 'WEEK' ? <Form.Group>
                    <Form.Label>{ t('translation:recurring.lblRepeatOn') }</Form.Label>
                    <DaySelector name='weekday' value={weekday} onChange={handleChange} />
                </Form.Group> : null }
                { freq === 'MONTH' ? <Form.Group>
                    <Form.Label>{ t('translation:recurring.lblRepeatOn') }</Form.Label>
                    <RadioInput name='in_month' value={in_month} onChange={handleChange}>
                        <RadioPane value='week_in_month'>
                            <NumberPicker name='week_in_month' min={1} max={5} value={week_in_month} onChange={handleChange} /><span> hét</span>
                            <DaySelector name='weekday' value={weekday} onChange={handleChange} />
                        </RadioPane>
                        <RadioPane value='day_in_month'>
                            <NumberPicker name='day_in_month' min={1} max={31} value={day_in_month} onChange={handleChange} /><span> nap</span>
                        </RadioPane>
                    </RadioInput>
                </Form.Group> : null }
            </Form>

        </div>
    </div>);
});


export const RecurringInput = ({ name, value: initValue, onChange = () => {} }: any) => {

    const { t } = useTranslation();

    const [recurring, setRecurring] = useState<any>({ day: 2, weekday: 4, week: 2 });
    const [open, setOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    useOnClickOutside(ref, () => {
        open && setOpen(false);
    });

    const handleOpen = () => {
        setOpen(true);
    };

    const handleChange = (value: RecurringType) => {
        //console.log(`change recurring: `, value);
        setRecurring(value)
        //setOpen(false);
    };

    return (<div className='calendar-input'>
        <div className='input-group'>
            <input className='form' 
                name={name} 
                value={ recurring ? describeRecurring(recurring) : t('translation:calendarInput.txtSelect') as string } />
            <span className='icon' onClick={handleOpen}><i className="las la-redo-alt"></i></span>
        </div>

        <RecurringDropdown ref={ref} 
            recurring={recurring} 
            open={open} onChange={handleChange} 
        />

    </div>);
}
