import React from 'react';

import { ToastProvider } from './ToastProvider';
import { UserProvider } from './UserProvider';
import { ModalProvider } from './ModalProvider';

const Providers = ({ children }: any) => {
    return (<ToastProvider>
        <UserProvider>
            <ModalProvider>
                { children }
            </ModalProvider>
        </UserProvider>
    </ToastProvider>);
}

export default Providers;