import React, { createContext, useContext, useState, useRef } from 'react';

import Modal from '../components/Modal';

type ModalContextType = {
    showModal: Function;
    closeModal: Function;
}

export const ModalContext = createContext<ModalContextType>({ showModal: () => {}, closeModal: () => {} });

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }: any) => {

    const [num, setNum] = useState(0);
    const contents = useRef<Array<JSX.Element>>([]);

    const showModal = (modalContent: JSX.Element) => {
        contents.current.push(modalContent);
        setNum(contents.current.length);
    }

    const closeModal = () => {
        contents.current.pop();
        setNum(contents.current.length);
    }

    return (<ModalContext.Provider value={{ showModal, closeModal }} >
        { children }
        { contents.current.map((content: any) => <Modal openModal={true} onClose={closeModal}>
            { content }
        </Modal>) }
    </ModalContext.Provider>);
};
