import React, { useState, useEffect, useRef } from "react";

import { useTranslation } from 'react-i18next';
import * as moment from 'moment';

import { MonthDay } from '../../../shared';
import { DatePointer, useCalendar } from '../../api/calendar';
import { useOnClickOutside } from '../../api/hooks';

const Day = ({ date, isInactive, isCurrent, onClick }: any) => {
    const className = `day ${isCurrent ? 'current' : isInactive ? 'inactive' : ''}`;
    return (<div className={className} onClick={() => onClick(date)}>{ date.day }</div>);
}

const CalendarDropdown = React.forwardRef<HTMLDivElement>(({ open, initValue, onChange = () => {} }: any, ref: any) => {

    const { value, current, days, jump } = useCalendar(initValue);
    const { t, i18n } = useTranslation();

    return (<div ref={ref} className={`dropdown-calendar ${open ? 'show' : ''}`}>
        <div className='calendar-header'>
            <div><span className='icon' onClick={() => jump(-1)}><i className="las la-angle-left"></i></span></div>
            <div className='header-title'>{ t('translation:calendar.formatYearMonth', { date: current }) }</div>
            <div><span className='icon' onClick={() => jump(1)}><i className="las la-angle-right"></i></span></div>
        </div>
        <div className='calendar-content'>
            <div className='day-names'>
                <span>Hé</span>
                <span>Ke</span>
                <span>Sze</span>
                <span>Csü</span>
                <span>Pé</span>
                <span>Szo</span>
                <span>Va</span>
            </div>

            { open && [0, 1, 2, 3, 4].map(weekNumber => {
                return (<div className="week">
                    {
                        [0, 1, 2, 3, 4, 5, 6].map(dayNumber => {
                            const index = weekNumber *  7 + dayNumber;
                            const day = days[index];

                            return day && <Day date={day} 
                                isInactive={current.month !== day.month} 
                                isCurrent={value && value.month === day.month && value.day === day.day && value.year === day.year}
                                onClick={() => onChange(day)} />
                        })
                    }
                </div>);
            }) }

        </div>
    </div>);
});

function parseMonthDay(value?: string): MonthDay | null {

    if (value === undefined || value === null) return null;
    
    const m = moment(value);
    return m ? {
        year: m.year(),
        month: m.month(), 
        day: m.date(), 
        date: m.format('YYYY-MM-DD'), 
    } : null;
    
}

export const CalendarInput = ({ name, value: initValue, onChange }: any) => {

    const { t, i18n } = useTranslation();

    const [date, setDate] = useState<MonthDay | null>();
    const [open, setOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setDate(parseMonthDay(initValue))
    }, [initValue]);

    useOnClickOutside(ref, () => {
        open && setOpen(false);
    });

    const handleOpen = () => {
        setOpen(true);
    };

    const handleChange = (value: MonthDay) => {
        setDate(value);
        setOpen(false);
        onChange && onChange(value?.date, name);
    };

    return (<div className='calendar-input'>
        <div className='input-group'>
            <input className='form' 
                name={name} 
                value={ date ? 
                    t('translation:calendar.formatLongDate', { date: date }) as string : 
                    t('translation:calendarInput.txtSelect') as string } />
            <span className='icon' onClick={handleOpen}><i className="las la-calendar"></i></span>
        </div>

        <CalendarDropdown ref={ref} initValue={initValue} open={open} onChange={handleChange} />

    </div>);
}
