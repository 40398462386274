import React, { useEffect, useRef, useState } from "react";

export const Text = ({ name, placeholder, value, autoFocus, onChange, onBlur, inputRef, ...props }: any) => {

    const ref = inputRef || useRef<HTMLInputElement>();

    useEffect(() => {
        autoFocus && setTimeout(() => ref && ref.current?.focus(), 100);
    }, [autoFocus])

    const handleChange = (e: any) => {
        const value = e.target.value;
        onChange && onChange(value, name);
    }

    const handleBlur = () => {
        onBlur && onBlur();
    }

    return (<input 
        className='form' 
        type='text'
        ref={ref}
        name={name} 
        value={value}
        placeholder={placeholder} 
        autoComplete='off'
        onChange={handleChange}
        onBlur={handleBlur}
        { ...props }
    />);

}

export const TextArea = ({ name, placeholder, value, autoFocus, onChange, ...props }: any) => {

    const ref = useRef<HTMLInputElement>();

    useEffect(() => {
        autoFocus && setTimeout(() => ref && ref.current?.focus(), 100);
    }, [autoFocus])

    const handleChange = (e: any) => {
        const value = e.target.value;
        onChange && onChange(value, name);
    }

    return (<textarea 
        ref={ref}
        name={name} 
        value={value}
        placeholder={placeholder} 
        onChange={handleChange}
        autoComplete='off'
        { ...props }
    />);

}

export const NumberPicker = ({ name, value, placeholder, autoFocus = false, min = 0, max = 100, 
    onChange = () => {}, 
    inputRef, ...props }: any) => {

    const ref = inputRef || useRef<HTMLInputElement>(null);

    useEffect(() => {
        autoFocus && setTimeout(() => ref && ref.current?.focus(), 100);
    }, [autoFocus])

    const handleKeyDown = (e: any) => {
        const keyCode = e.keyCode;
    }

    const handleChange = () => {
    }

    const change = (step: number) => {
        const nextValue = value + step;
        if (nextValue >= min && nextValue <= max) onChange(value + step, name);
    }

    return <div className='input-group'>
        <span className='icon' onClick={() => change(-1)}><i className="las la-minus-circle"></i></span>
        <input className='form' name={name} 
            ref={ref}
            type='text' 
            value={value}
            placeholder={placeholder} 
            onChange={handleChange} 
            onKeyDown={handleKeyDown} />
        <span className='icon' onClick={() => change(1)}><i className="las la-plus-circle"></i></span>
    </div>

}

// TODO: finish
export const RegExpInput = ({ name, value, regex, placeholder, autoFocus = false, 
    onChange = () => {}, 
    inputRef, ...props }: any) => {

    const ref = inputRef || useRef<HTMLInputElement>(null);
    const [store, setStore] = useState<string | null>(null);

    useEffect(() => {
        autoFocus && setTimeout(() => ref && ref.current?.focus(), 100);
    }, [autoFocus])

    const handleKeyDown = (e: any) => {
        const keyCode = e.keyCode;
    }

    const handleChange = () => {
        
    }

    return <div className='input-group'>
        <input className='form' name={name} 
            ref={ref}
            type='text' 
            value={store ? store: value}
            placeholder={placeholder} 
            onChange={handleChange} 
            onKeyDown={handleKeyDown} />
    </div>

}


export const PasswordInput = ({ name, value, autoFocus = false, 
    onChange = () => {}, 
    inputRef, ...props }: any) => {

    const ref = inputRef || useRef<HTMLInputElement>(null);
    const [show, setShow] = useState<boolean>(false);

    useEffect(() => {
        autoFocus && setTimeout(() => ref && ref.current?.focus(), 100);
    }, [autoFocus])

    const handleChange = (e: any) => {
        const value = e.target.value;
        onChange && onChange(value, name);
    }

    const handleShow = () => {
        setShow(prev => !prev);
    }

    return <div className='input-group'>
        <input className='form' name={name} 
            ref={ref}
            type={show ? 'text' : 'password'} 
            value={value}
            autoComplete='off'
            onChange={handleChange} />
        <span className='icon' onClick={handleShow}><i className={`las la-${show ? 'eye-slash' : 'eye'}`}></i></span>
    </div>

}
