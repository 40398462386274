import React, { useState, useEffect, useRef } from "react";

import { useFile } from '../api/files';

const fileIcons = {
    'pdf': 'la-file-pdf',
    'doc': 'la-file-word',
    'sheet': 'la-file-excel'
}

export const FileList = ({ id, list, readOnly = false, onFinished = () => {}, onRemoved = () => {} } : any) => {

    const { uploadFile, uploadProgress, cancelUpload, getDownloadURL } = useFile();

    const handleOpenFile = (id: string) => {
        getDownloadURL(id).then((url: string) => {
            window.open(url, '_blank');
        });
    };

    return <ul className='file-list'>
        { 
            list && list.map((item: any) => {
                return <FileList.File id={item.id} 
                    name={item.file_name} 
                    type='xxx' 
                    hasDelete={!readOnly} 
                    onClick={handleOpenFile} 
                    onDelete={onRemoved} />
            }) 
        }
        { !readOnly ? <FileList.AddFile 
            title='Fájl feltöltése' 
            onFileSelected={uploadFile} 
            onCancel={cancelUpload} 
            onFinished={onFinished} 
            progress={uploadProgress} /> : null }
    </ul>
}

const FileIcon = ({ type }: any) => {
    const cn = fileIcons[type] || 'la-file';
    return <i className={`las ${ cn } file-size`}></i>
}

const UploadProgress = ({ value = 0 }: any) => {

    const style = {
        width: `${value}%`
    };

    return <div className='progress'>
    <div className='filler' style={style}>
      <span className='label'>{`${value}%`}</span>
    </div>
  </div>
}

FileList.File = ({ id, name, type, url, onClick = () => {}, hasDelete = false, onDelete = () => {} }: any) => {

    return <li className="list-item">
        <div className='file-part'>
            <div className='icon'><FileIcon type={type} /></div>
            <div className='filename'><span onClick={() => onClick(id, url) }>{name}</span></div>
        </div>
        <div className='actions-part'>
            { hasDelete ? <i className="las la-trash file-size" onClick={onDelete}></i> : '' }
        </div>
    </li>
}

FileList.AddFile = ({ title, onFileSelected = () => {}, onCancel = () => {}, onFinished = () => {}, progress }: any) => {

    const ref = useRef<HTMLInputElement>(null);

    const handleClick = () => {
        ref.current?.click();
    }

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        onFileSelected && onFileSelected(files, onFinished);
    }

    return <li className="list-item">
        { !progress ?
            <div className='file-part add-new'>
                <div className='icon'><i className="las la-plus-circle file-size"></i></div>
                <input type='file' ref={ref} style={{display: 'none'}} onChange={handleOnChange} />
                <div className='filename'><span onClick={handleClick}>{ title }</span></div>
            </div>
        : <><UploadProgress value={progress} />
            { (progress < 100) ? <div className='actions-part'>
                <i className="las la-times-circle file-size" onClick={onCancel}></i>
                </div> : null }
            </>
        }
    </li>
}

FileList.ProgressBar = ({ completed }: any) => {

    return <li className="list-item">
        <UploadProgress value={completed} />
        <div className='actions-part'>
            <i className="las la-times-circle file-size"></i>
        </div>
    </li>
}

