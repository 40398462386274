import React, { useState } from 'react';

import { useModal } from '../providers/ModalProvider';

import { Button, Text, Form, Dialog, EditableTitle, DropdownButtons, CalendarInput, TagsInput, TextArea, Tabs, Tabs2, 
    RecurringInput, RadioInput, RadioButton, NumberPicker, UnitInput, PasswordStrength } from '../components';

export default function UIKitPage({ location }: any) {

    const modal = useModal();

    const [vari, setVari] = useState(3);

    const style = {
        backgroundColor: 'white',
        padding: '10px'

    }

    const handleCloseModal = () => {
        modal.closeModal();
    }

    const newModal = () => {
        modal.showModal('Hehehe')
    }

    const showModal = (e: any) => {

        modal.showModal(<Dialog 
            header={
                <EditableTitle name='summary' title={'Eszközök...'} onChange={handleChange} />
            }
            footer={<>
                <Button onClick={handleCloseModal} variant='primary'>{'Mentés'}</Button>
                <Button onClick={handleCloseModal}>{'Mégsem'}</Button>
            </>}
            onClose={handleCloseModal}>
            <Form>
                <Tabs selected='task_definition'>
                    <Tabs.Tab name='task_definition' title={'első oldal'} icon='pencil-ruler'>

                        <div className='row'>
                            <div className='w60'>
                                <Button onClick={newModal}>Modal</Button>
                            </div>
                            <div className='w40'>
                                w40
                            </div>
                        </div>
                    </Tabs.Tab>
                    <Tabs.Tab name='task_working' title={'második oldal'} icon='hammer'>
                        valami
                    </Tabs.Tab>
                </Tabs>
            </Form>
        </Dialog>);

    }

    //console.log('render UIKIt')

    const handleChange = (value: any, name: string) => {
        console.log(`handleChange: ${name}: ${value}`)

        if (name === 'varika') {
            setVari(value);
        }
    }

    const handleTabChange = (name: string) => {
        console.log(`handleTabChange: ${name}`)
    }

    return (<div style={style}>
        <p>Itt találhatók a UIKit egyes elemei:</p>

        <Button variant='primary'>Primary</Button>
        <Button variant='red'>Danger</Button>
        <Button variant='green'>Green</Button>
        <Button variant='primary' size='small'>Primary</Button>
        <Text name='test01' value='Teszt érték' />
        <Button variant='red' icon='plus'>Hello</Button>
        <Form>
            <Form.Group>
                <Form.Label>Felhasználói név</Form.Label>
                <Text name='username' value='Teszt érték' />
                <Form.Message value='Üzenet....' />
            </Form.Group>
        </Form>

        <Button variant='red' onClick={showModal}>Modal</Button>
        <div className='half'>
            <RecurringInput initValue='2021-03-02' />
        </div>
        <div className='half'>
            <PasswordStrength value={80} strengths={[
                    { level: 33, title: 'Gyenge', color: '#fe4758' }, 
                    { level: 66, title: 'Közepes', color: '#fea500' }, 
                    { level: 100, title: 'Erős', color: '#23ac5e' }, 
                ]}
            />
        </div>

        <RadioInput name='test_radio' value='masodik' onChange={handleChange}>
            <RadioButton title='Első elem' value='elso' />
            <RadioButton title='második elem' value='masodik' />
        </RadioInput>

        <NumberPicker name='varika' min={0} max={10} value={vari} onChange={handleChange} />

        <br/>
        <br/>
        <br/>

        <div className='dlg-wrapper'>
            <div className='top-panel'>Ez itt a cím</div>
            <div className='content'>
                <p>There’s nothing new inside of it — the logic is copied from the components above. Just like in a component, make sure to only call other Hooks unconditionally at the top level of your custom Hook.</p>
                <p>There’s nothing new inside of it — the logic is copied from the components above. Just like in a component, make sure to only call other Hooks unconditionally at the top level of your custom Hook.</p>

            </div>
            <div className='footer'>
                <Button>Mentés</Button>
                <Button>Mégsem</Button>
            </div>
        </div>

        <br/>
        { /* <MALogo /> */ }

        <EditableTitle title='Hellóka'  />

        <DropdownButtons name='test' 
            value='Hello' 
            list={['első', 'második', 'harmadik']} 
            onSelect={handleChange} />

        <CalendarInput initValue='2021-03-02' />


        <TagsInput value={[
                { id: 10, title: 'Nagy Barna', color: 'red' },
                { id: 11, title: 'Mosó 123', color: 'blue' },
            ]} 
            dataList={[
                { id: 10, title: 'Nagy Barna', color: 'red' },
                { id: 11, title: 'Mosó 123', color: 'blue' },
                { id: 12, title: 'Alma', color: 'blue' },
                { id: 13, title: 'Körte', color: 'blue' },
            ]}
        
        />

        <UnitInput units={[
            { id: 'KM', title: 'kilométer' }, 
            { id: 'HOUR', title: 'üzemóra' }, 
        ]} 
        value='100' unit='HOUR' 
        onChange={(value: string) => console.log(value)} />

        <TextArea value={`hellóka`} />


        <Tabs selected='harmadik' onChange={handleTabChange}>
            <Tabs.Tab name='resztl' selected={true} title='Részletes leírás'>
                <div>
                    <p className='desc'>A feladathoz elvégzésének a részletes leírása (amennyiben szükséges):</p>
                </div>
            </Tabs.Tab>
            <Tabs.Tab name='masodik' disabled={true} title='Eszköz dokumentációk'>
            </Tabs.Tab>
            <Tabs.Tab name='harmadik' title='Csatolmányok'>
                <p className='desc'>A feladathoz csatolt és generált dokumentumok:</p>

            </Tabs.Tab>
            <Tabs.Tab name='negyedik' title='Dokumentum generálás'>
                <p className='desc'>Válassza ki, hogy milyen dokumentumot szeretne generálni:</p>
            </Tabs.Tab>
        </Tabs>
        <br/>
        <br/>
        <Tabs2 />
        <br/>
        <br/>


    </div>);

}

