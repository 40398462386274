import React, { useState, useMemo, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

import { useTask } from '../../api/task';
import { usePersonsQuery } from '../../api/person';
import { useAssetsQuery, useAssetAttactments } from '../../api/asset';
import { Dialog, CloseButton, Form, Button, Text, EditableTitle, DropdownButtons, DropdownList, 
    CalendarInput, TagsInput, TextArea, Tabs, 
    FileList, LoadingOverlay, Spinner } from '../../components';

const SaveController = ({ modified, save: saveTask, t }: any) => {

    const handleClick = () => {
        saveTask();
    }

    return <div>{ modified == 1 ? 
        <button className='btn btn-small btn-primary' onClick={handleClick}>{ t('translation:details.btnSave') }</button> : modified == 2 ? <span>{ t('translation:details.lblSaved') }&nbsp;</span> : '' }
    </div>;
}

const TaskDetails = ({ id, task: _initTask, onClose }: any) => {

    const { t } = useTranslation();
    const { task, isLoading, initTask, loadTask, updateTask, saveTask, cancelSaving, 
        isModified, validateTask, errors } = useTask();
    const { summary, description, status, due_date, accountable, asset, attachments, 
        work_performed, performed_by, performed_at } = task;

    useEffect(() => {
        if (id) {
            loadTask(id);
        } else if (_initTask) {
            initTask(_initTask);
        }
    }, []);

    const [ personsFilter, setPersonsFilter ] = useState('');
    const { persons } = usePersonsQuery(personsFilter);
    const personsData = useMemo(() => {
        return persons.map((person: any) => ({
            id: person.id,
            title: `${person.family_name} ${person.given_name}`, 
        }))
    }, [persons]);

    const [ assetsFilter, setAssetsFilter ] = useState('');
    const { assets } = useAssetsQuery(assetsFilter);
    const assetsData = useMemo(() => {
        return assets.map((asset: any) => ({
            id: asset.id,
            title: asset.name, 
        }))
    }, [assets]);

    const { attachments: asset_files } = useAssetAttactments(task?.asset?.id);

    const statusesDropdownData = [
        { id: 'TODO', title: t('translation:task.titleTODO') }, 
        { id: 'IN_PROGRESS', title: t('translation:task.titleIN_PROGRESS') }, 
        { id: 'WAITING', title: t('translation:task.titleWAITING') }, 
        { id: 'DONE', title: t('translation:task.titleDONE') }, 
        { id: 'CLOSED', title: t('translation:task.titleCLOSED') }, 
        { id: 'DELETED', title: t('translation:task.titleDELETED') }, 
    ];

    const handleClose = () => {
        cancelSaving();
        onClose && onClose();
    }

    // mapChangeToObject
    const handleChange = (value: any, name: string) => {
        //console.log('change value: ', name, value);

        if (name === 'asset') {
            const asset = value[0];
            if (asset) {
                updateTask({ 'asset': {
                    id: asset.id,
                    name: asset.title
                } });
            } else updateTask({ 'asset': null });
            return;
        }

        if (name === 'accountable') {
            const person = value[0];
            if (person) {
                updateTask({ 'accountable': {
                    id: person.id,
                    name: person.title
                } });
            } else updateTask({ 'accountable': null });
            return;
        }

        if (name === 'performed_by') {
            const person = value[0];
            if (person) {
                updateTask({ 'performed_by': {
                    id: person.id,
                    name: person.title
                } });
            } else updateTask({ 'performed_by': null });
            return;
        }

        updateTask({ [name]: value });

        // automatic fillings
        if (name === 'status' && value === 'DONE') {

        }


    }

    const handleSave = () => {
        if (validateTask()) {
            saveTask();
        }
    }

    const handleFinished = () => {
        console.log('finished upload');
        /*objectId.current && updateStatus(objectId.current, 'ACTIVE').then((resp: any) => {
            console.log('updated binary object')
            setProgress(null);
        }).catch((error: any) => {
            console.error(error);
        });*/
    }

    const handleValidate = () => {
        validateTask();
    }

    //console.log('render TaskDetails')
    //console.log('errors: ', errors)

    const errorMessages = useMemo(() => {

        const ret = [];
        for (const [key, value] of Object.entries(errors)) {
            if (value) {
                ret.push(value);
            }
        }

        return ret;
    }, [errors]);
    

    return (
    //{ /*<LoadingOverlay isLoading={isLoading} spinner={<Spinner/>}> */}
        <Dialog 
            header={ <>
                <div className='dlg-type'>{ t('translation:task.titleDlgType') }</div>
                <EditableTitle name='summary' title={ summary } onChange={handleChange} />
            </> }
            headerRightButtons={<>
                <SaveController t={t} modified={isModified} save={handleSave} />
                <CloseButton onClose={handleClose} />
            </>}
            footer={<>
                <div>
                    { /* <Button onClick={handleSave} variant='primary'>{ t('translation:task.btnSave') }</Button>
                    <Button onClick={handleValidate}>{'Validálás'}</Button> */ }
                </div>
                <Button onClick={handleClose}>{ t('translation:task.btnCancel') }</Button>
            </>}
            errorMessages={errorMessages}
            onClose={handleClose}>
                { /* <Form> */ }
                    <Tabs selected='task_definition'>
                        <Tabs.Tab name='task_definition' title={ t('translation:task.tabTaskPlanning') } icon='pencil-ruler'>

                            <div className='row'>
                                <div className='w60'>
                                    <Form.Group>
                                        <Form.Label>{ t('translation:task.lblDescription') }</Form.Label>
                                        <TextArea name='description' value={description} onChange={handleChange} />
                                    </Form.Group>
                                    <Tabs selected='attachments'>
                                        <Tabs.Tab name='asset_files' disabled={asset_files.length === 0} title={ t('translation:task.tabAssetFiles') }>
                                            <FileList 
                                                list={asset_files} 
                                                readOnly={true} />
                                        </Tabs.Tab>
                                        <Tabs.Tab name='attachments' title={ t('translation:task.tabAttachments') }>
                                            <FileList 
                                                list={attachments} 
                                                onFinished={handleFinished} />
                                        </Tabs.Tab>
                                     { /* <Tabs.Tab name='document_generation' title={ t('translation:task.tabDocsGeneration') }>
                                            <p className='desc'>Válassza ki, hogy milyen dokumentumot szeretne generálni:</p>
                                        </Tabs.Tab> */}
                                    </Tabs>
                                </div>
                                <div className='w40'>
                                    <Form.Group>
                                        <Form.Label>{ t('translation:task.lblDueDate') }</Form.Label>
                                        <CalendarInput name='due_date' value={due_date} onChange={handleChange} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>{ t('translation:task.lblAsset') }</Form.Label>
                                        <TagsInput name='asset' 
                                            value={asset ? [{ id: asset.id, title: asset.name }] : []} dataList={assetsData} 
                                            onChange={handleChange}
                                            onFilter={((filter: string) => setAssetsFilter(filter))} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>{ t('translation:task.lblAccountable') }</Form.Label>
                                        <TagsInput name='accountable' 
                                            value={accountable ? [{ id: accountable.id, title: accountable.name }] : []} dataList={personsData} 
                                            onChange={handleChange}
                                            onFilter={((filter: string) => setPersonsFilter(filter))} />
                                    </Form.Group>
                                </div>
                            </div>
                        </Tabs.Tab>
                        <Tabs.Tab name='task_working' title={ t('translation:task.tabTaskPerforming') } icon='hammer'>

                            <div className='row'>
                                <Form.Group>
                                    <Form.Label>{ t('translation:task.lblStatus') }</Form.Label>
                                    <DropdownList name='status' 
                                        value={status}
                                        onChange={handleChange}
                                        dataList={statusesDropdownData} 
                                        />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>{ t('translation:task.lblPerformedBy') }</Form.Label>
                                    <TagsInput name='performed_by' 
                                        value={performed_by ? [{ id: performed_by.id, title: performed_by.name }] : []} dataList={personsData} 
                                        onChange={handleChange}
                                        onFilter={((filter: string) => setPersonsFilter(filter))} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>{ t('translation:task.lblPerformedAt') }</Form.Label>
                                    <CalendarInput name='performed_at' value={performed_at} onChange={handleChange} />
                                </Form.Group>
                            </div>

                            <Form.Group>
                                <Form.Label>{ t('translation:task.lblWorkPerformed') }</Form.Label>
                                <TextArea name='work_performed' value={work_performed} onChange={handleChange} />
                            </Form.Group>



                        </Tabs.Tab>
                    </Tabs>

                { /* </Form> */ }
        </Dialog>

    //{/*</LoadingOverlay> */}
    
    );
};

export default TaskDetails;