
import { VNode } from './types';

export default class VTag implements VNode {
    private _text: string;

    private _rendered: Node;
    private _renderedText: Node;

    constructor(text: string) {
        this._text = text;
    }

    public render() {
        const span = document.createElement('span');
        span.setAttribute('id', 'tabocska');
        span.setAttribute('contenteditable', 'false');
        span.setAttribute('style', 'cursor: pointer; background: lightgreen; border: 1px solid black; padding: 2px 4px;');
        const text = document.createTextNode(this._text);
        span.appendChild(text);

        this._renderedText = text;
        this._rendered = span;
        return this._rendered;
    }

    public containsNode(node: Node) {
        return (this._rendered === node || this._renderedText === node) ? this : false;
    }

    public update(action: any, cursor: any) {}

    public setCursorPosition(cursor: any) {}

}