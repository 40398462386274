import React from "react";
import { useTranslation } from 'react-i18next';

import { Page, PageContent, FileList } from '../../components';
import { usePersonsQuery } from '../../api/person';

const personName = (name: string, family_name: string, given_name: string) => {
    if (family_name && given_name) return `${family_name} ${given_name}`;
    return name ? name : 'n/a';
}

const PersonnelItem = ({ item, onClick }: any) => {

    const { id, name, family_name, given_name, email, licences } = item;

    const handleClick = () => {
        onClick && onClick(id);     
    }

    return (<tr onClick={handleClick}>
        <td className='event-title'>{ personName(name, family_name, given_name) }</td>
        <td>{ email }</td>
        <td>{
            licences && licences.map((licence: any) => <span className='tag'>{ licence.name }</span>)
        }</td>
    </tr>);

}


const PersonnelList = ({ list, onSelect }: any) => {

    const handleClick = (id: number) => {
        onSelect && onSelect(id);
    }

    return (<div className='ma-table'>
        <table className='ma-table'>
        <thead>
            <tr>
                { 
                    ['Név', 'E-mail cím', 'Jogosultságok, engedélyek'].map(column => {
                        return <th scope="col">{column}</th>
                    }) 
                }
            </tr>
        </thead>
        <tbody>
            { list.map((person: any) => <PersonnelItem 
                item={person}
                onClick={handleClick} 
                />) }
        </tbody>
        </table>
    </div>)
}

export default function PersonnelPage({ location }: any) {

    const { t } = useTranslation();
    const { persons, loadPersonList } = usePersonsQuery('');

    const personnel = [
        { id: 1600, name: 'Nagy Barna', email: 'nagy.barna@blx.hu', licences: [{ id: 10, title: ''}, ] }, 
    ];

    return (<Page title={ t('translation:pages.personnel') } to={ location.pathname }>
        <PageContent>
            <p>Ez a funkció 2022. első negyedévében lesz elérhető.<br/></p>
            <img src='images/illustrations/szemelyzet.png' />

            { /* <PersonnelList 
                list={persons}
            /> */ }
        </PageContent>
    </Page>);

}

