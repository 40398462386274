
import { VNode } from './types';
import { setCaret, isEditorChar } from './utils'


export default class VText implements VNode {
    private _text: string;
    private _rendered: Node;
    private _shouldRender: boolean = true;
    private _hasFocus: boolean = false;

    constructor(text: string) {
        this._text = text;
    }

    public render() {
        if (this._shouldRender) {
            this._rendered = document.createTextNode(this._text);
            this._shouldRender = false;
        }

        return this._rendered;
    }

    public containsNode(node: Node) {
        return (this._rendered === node) ? this : false;
    }

    public update(action: any, cursor: any) {
        if (!action) return;

        switch (action.type) {
            case 'FOCUS': 
                this._hasFocus = true;
                console.log('VText has focus');
                break;
            case 'BLUR': 
                this._hasFocus = false;
                console.log('VText lost focus');
                break;

            case 'KEY_DOWN': 
                if (isEditorChar(action.key)) {
                    this._text = this._text.splice(cursor.offset, 0, action.key);
                    this._shouldRender = true;
                    cursor.offset = cursor.offset + 1;
                } else if (action.key === 'Backspace') {
                    if (cursor.offset > 0) {
                        this._text = this._text.splice(cursor.offset - 1, 1, '');
                        this._shouldRender = true;
                        cursor.offset = cursor.offset - 1;
                    } else return action;
                } else if (action.key === 'ArrowLeft' && cursor.offset > 0) {
                    cursor.offset = cursor.offset - 1;
                } else if (action.key === 'ArrowRight' && cursor.offset < this._text.length) {
                    cursor.offset = cursor.offset + 1;
                } else return action;

                break;

            case 'MOVING': 
                if (action.key === 'ArrowLeft' && cursor.offset > 0) {
                    cursor.offset = cursor.offset - 1;
                } else if (action.key === 'ArrowRight' && cursor.offset < this._text.length) {
                    cursor.offset = cursor.offset + 1;
                }
                break;
        }

    }

    public setCursorPosition(cursor: any) {
        setCaret(this._rendered, cursor.offset);
    }

}
