import React, { useState, useEffect, useRef } from "react";
import axios, { Canceler } from 'axios';

export const useProcess = (processId: number) => {

    const [process, setProcess] = useState<any>(null);
    const [isLoading, setLoading] = useState(false);
    const cancelRef = useRef<Canceler>();

    const saveProcess = (objs: any) => {
        console.log('saving process');

        axios.put(`/api/process/${processId}`, objs, {
            cancelToken: new axios.CancelToken(c => {
                cancelRef.current = c;
            })
        }).then(resp => {
            console.log('saved....')

        }).catch(error => {
            setLoading(false);

            console.log('error: ', error)

            if (axios.isCancel(error)) {
              //this is now expected
            }
        });


    }

    const fetchData = () => {
        setLoading(true);

        axios.get(`/api/process/${processId}`, {
            cancelToken: new axios.CancelToken(c => {
                cancelRef.current = c;
            })
        }).then(resp => {

            setProcess(resp.data);
            setLoading(false);

        }).catch(error => {
            setLoading(false);

            console.log('error: ', error)

            if (axios.isCancel(error)) {
              //this is now expected
            }
        });

    }

    const updateProcess = (text: string, elementId: string) => {
        const _elements = [ ...process.elements];
        console.log('elements: ', _elements)
        const _element = _elements.find((element: any) => element.id === elementId);

        if (_element) {
            _element.text = text + 'XXXX';
            setProcess({ ...process, elements: _elements });
        }

    }

    useEffect(() => {
        fetchData();
    }, [processId]);

    return { 
        process, 
        isLoading, 
        saveProcess,
        updateProcess,
    };

}

