import React from "react";
import { useTranslation } from 'react-i18next';

import { Page, PageContent, FileList } from '../components';
import { useFiles } from '../api/files';

export default function DocumentsPage({ location }: any) {

    const { t } = useTranslation();
    const { files } = useFiles();

    return (<Page title={ t('translation:pages.documents') } to={ location.pathname }>
        <PageContent>
            <p>A szervezethez tartozó dokumentumok:</p>
            <FileList readOnly={true} list={files} />
        </PageContent>
    </Page>);

}

