import { useState, useEffect, useRef, useCallback, useReducer, Reducer } from "react";
import { Canceler } from 'axios';

import { useAPI, debounce, Modification } from './index';
import { useValidator } from './validator';
import { check } from './check';
import { today } from '../utils';
import { TaskScheduling } from './types';

interface AssetType {
    id?: number;
    name: string;
    make?: string;
    model?: string;
    inventory_number?: string;
    comment?: string;

    groups?: Array<any>;
    loadValue?: number;
    loadUnit?: string; 
    attachments?: Array<any>;
}

type StateType = {
    asset: AssetType;
    isLoading: boolean;
    isModified: Modification;
    errorMessages: any;
}

const emptyAsset: AssetType = {
    id: undefined,
    name: 'Új eszköz',
    attachments: [], 
}

const initialState: StateType = {
    asset: emptyAsset,
    isLoading: false,
    isModified: Modification.Untouched, 
    errorMessages: {}
}

const reducer = (state: StateType, action: any): StateType => {
    switch (action.type) {
        case 'INIT':
            return { asset: action.payload, isLoading: state.isLoading, isModified: state.isModified, errorMessages: {} }
        case 'LOADING':
            return { ...state, isLoading: true }
        case 'LOADED':
            return { asset: action.payload, isLoading: false, isModified: Modification.Untouched, errorMessages: {} }
        case 'MODIFIED':
            return { asset: action.payload, isLoading: state.isLoading, isModified: Modification.Modified, errorMessages: state.errorMessages }
        case 'VALIDATED':
            return { ...state, errorMessages: action.payload }
        case 'CREATED':
            return { ...state, isModified: Modification.Saved, asset: { ...state.asset, id: action.payload } }
        case 'SAVED':
            return { ...state, isModified: Modification.Saved }
        default:
            return state;
    }
}

export const useAsset = (onForbidden?: Function, onError?: Function) => {

    const [state, dispatch] = useReducer<Reducer<StateType, any>>(reducer, initialState);
    const cancelRef = useRef<Canceler>();

    const { fetchData, updateData, createData, cancel } = useAPI({ cancelRef, onError, onForbidden });
    const { validate } = useValidator({});

    const _initAsset = (_asset: AssetType) => {
        dispatch({ type: 'INIT', payload: _asset });
    }

    const _loadAsset = async (assetId: number) => {
        dispatch({ type: 'LOADING' });

        return fetchData(`/api/asset/${assetId}`).then(resp => {
            dispatch({ type: 'LOADED', payload: resp.data });
        }).catch((error: any) => {});
    }

    const saveAsset = async (asset: AssetType) => {
        
        const assetId = asset.id;

        const _asset = {
            name: asset.name,
            make: asset.make,
            model: asset.model,
            inventory_number: asset.inventory_number,
            comment: asset.comment,
            loadValue: asset.loadValue,
            loadUnit: asset.loadUnit,
            groups: asset.groups, 
            attachments: asset.attachments, 
        };

        if (assetId) {
            return updateData(`/api/asset/${assetId}`, _asset)
            .then((resp: any) => {
                dispatch({ type: 'SAVED' });
                return assetId;
            })
            .catch((error: any) => {});
        } else {
            return createData(`/api/asset`, _asset)
            .then((resp: any) => {
                const assetId = resp.data.id;
                dispatch({ type: 'CREATED', payload: assetId});
                return assetId;
            })
            .catch((error: any) => {});

        }

    }

    const debouncedSaveAsset = useCallback(
        debounce((_asset: any) => {
            _asset.id && saveAsset(_asset);
        }, 6000), 
        []
    );

    const _updateAsset = (changes: any) => {
        const _asset = Object.assign({}, state.asset, changes);

        dispatch({ type: 'MODIFIED', payload: _asset });
        debouncedSaveAsset(_asset);
    }

    const updateAssetFiles = () => {
        const assetId = state.asset.id;

        if (assetId) {
            updateData(`/api/asset/${assetId}/attachments`, state.asset.attachments).catch((error: any) => {});
        }
    }

    const _saveAsset = (_asset: AssetType) => {
        debouncedSaveAsset.cancel();
        return saveAsset(_asset);
    }

    const _validateAsset = () => {
        const _errorMessages = validate(state.asset);
        dispatch({ type: 'VALIDATED', payload: _errorMessages });
        //console.log('_validateAsset() - _errors: ', _errorMessages)
        return Object.keys(_errorMessages).length === 0 ? true : false;
    }

    const _cancelSaving = () => {
        debouncedSaveAsset.cancel();
    }

    return { 
        asset: state.asset, 
        initAsset: _initAsset, 
        loadAsset: _loadAsset, 
        updateAsset: _updateAsset, 
        updateAssetFiles, 
        saveAsset: () => _saveAsset(state.asset), 
        cancelSaving: _cancelSaving, 
        isLoading: state.isLoading, 
        isModified: state.isModified, 

        validateAsset: _validateAsset,
        errors: state.errorMessages, 
    };

}


export const useAssetsQuery = (filter: string, onForbidden?: Function, onError?: Function) => {

    const [isLoading, setLoading] = useState(false);
    const [assets, setAssets] = useState<Array<AssetType>>([]);
    const [filteredAssets, setFilteredAssets] = useState<Array<AssetType>>([]);
    const cancelRef = useRef<Canceler>();
    //const hash = useRef<string>('');

    const { fetchData: getData } = useAPI({ cancelRef, onError, onForbidden });

    const loadAssets = () => {

        getData(`/api/asset`).then(resp => {
            const assets = resp.data || [];
            setAssets(assets);
            //hash.current = `${new Date().getTime()}`;
            filterData(assets);
        }).catch((error: any) => {});

    }

    const filterData = (assets: Array<AssetType>) => {
        const _filtered = assets.filter(asset => {
            return asset.name?.toLowerCase().includes(filter.toLowerCase());
        });
        setFilteredAssets(_filtered);
    };

    useEffect(() => {
        loadAssets();
    }, []);

    useEffect(() => {
        filterData(assets);
    }, [filter]);

    return { 
        assets: (filteredAssets.length > 0) ? filteredAssets : assets, 
        //hash: hash.current, 
        isLoading, 
        loadAssets, 
    };

}


interface AssetGroupType {
    id: number;
    name: string;    
}

export const useAssetGroupsQuery = (filter: string, onForbidden?: Function, onError?: Function) => {

    const [isLoading, setLoading] = useState(false);
    const [groups, setGroups] = useState<Array<AssetGroupType>>([]);
    const [filteredGroups, setFilteredGroups] = useState<Array<AssetGroupType>>([]);
    const cancelRef = useRef<Canceler>();

    const { fetchData: getData, createData } = useAPI({ cancelRef, onError, onForbidden });

    const fetchData = () => {
        return getData(`/api/asset_group`).then(resp => {
            setGroups(resp.data);
            filterData();
        }).catch((error: any) => {});
    };

    const filterData = () => {
        const _filtered = groups.filter(group => {
            return group.name?.toLowerCase().includes(filter.toLowerCase());
        });
        setFilteredGroups(_filtered);
    };

    const createGroup = (name: string) => {
        return createData('/api/asset_group', { name: name }).then(resp => {

            const groupId = resp.data.id;

            return fetchData().then((result: any) => {
                return groupId;
            });

        }).catch((error: any) => {});
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        filterData();
    }, [filter]);

    return { 
        assetGroups: (filteredGroups.length > 0) ? filteredGroups : groups, 
        createGroup, 
        isLoading, 
    };

}

interface AssetAttachmentsType {
    id: number;
    file_name: string;   
    file_size: number; 
}

export const useAssetAttactments = (assetId: number, onForbidden?: Function, onError?: Function) => {

    const [isLoading, setLoading] = useState(false);
    const [files, setFiles] = useState<Array<AssetAttachmentsType>>([]);
    const cancelRef = useRef<Canceler>();

    const { fetchData: getData } = useAPI({ cancelRef, onError, onForbidden });

    const fetchData = () => {
        getData(`/api/asset/${assetId}/attachments`).then(resp => {
            setFiles(resp.data || []);
        }).catch((error: any) => {});
    };

    useEffect(() => {
        assetId && fetchData();
    }, [assetId]);

    return {
        attachments: files, 
        isLoading, 
    };

}

interface AssetTasksType {
    task_id: number;
    summary: string;
    status: string;
    due_date: string;
}

export const useAssetTasks = (assetId: number, onForbidden?: Function, onError?: Function) => {

    const [isLoading, setLoading] = useState(false);
    const [tasks, setTasks] = useState<Array<AssetTasksType>>([]);
    const cancelRef = useRef<Canceler>();

    const { fetchData: getData } = useAPI({ cancelRef, onError, onForbidden });

    const fetchData = () => {
        getData(`/api/asset/${assetId}/tasks`).then(resp => {
            setTasks(resp.data || []);
        }).catch((error: any) => {});
    };

    useEffect(() => {
        assetId && fetchData();
    }, [assetId]);

    return {
        tasks, 
        isLoading, 
    };

}

interface AssetLogType {
    value: number;
}

export const useAssetLogs = (assetId: number, onForbidden?: Function, onError?: Function) => {

    const [logs, setLogs] = useState<Array<AssetLogType>>([]);

    const cancelRef = useRef<Canceler>();
    const { fetchData, createData } = useAPI({ cancelRef, onError, onForbidden });

    const fetchLogs = () => {
        fetchData(`/api/asset/${assetId}/log`).then(resp => {
            setLogs(resp.data || []);
        }).catch((error: any) => {});
    };

    const _insertLog = (log: AssetLogType) => {

        const _log = {
            value: log.value,
            recordDate: today(),
        }

        createData(`/api/asset/${assetId}/log`, _log)
        .then((resp: any) => {
            //dispatch({ type: 'SAVED' });
            //console.log('inserted new Asset Log')
        })
        .catch((error: any) => {});
    }

    useEffect(() => {
        assetId && fetchLogs();
    }, [assetId]);

    return { 
        logs,
        insertLog: _insertLog
    };

}

export const useAssetSchedulings = (onForbidden?: Function, onError?: Function) => {

    const [isLoading, setLoading] = useState(false);
    const [schedulings, setSchedulings] = useState<Array<TaskScheduling>>([]);
    const cancelRef = useRef<Canceler>();

    const { fetchData: getData } = useAPI({ cancelRef, onError, onForbidden });

    const _load = async (assetId: number) => {
        getData(`/api/asset/${assetId}/scheduling`).then(resp => {
            setSchedulings(resp.data || []);
        }).catch((error: any) => {});
    }

    /*
    useEffect(() => {
        assetId && _load(assetId);
    }, [assetId]);*/

    return {
        schedulings, 
        loadAssetSchedulings: _load, 
        isLoading, 
    };

}
