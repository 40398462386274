import * as moment from 'moment';

export type DateString = string;

export interface MonthDay {
    year: number;
    month: number;
    day: number;
    date: DateString;
    events?: Array<any>
}


export const monthDays = (year: number, month: number): Array<MonthDay> => {

    const m = moment({ year: year, month: month, day: 1 });
    const firstWeekDay = m.isoWeekday();

    const ret: Array<any> = [];
    const add = () => {
        ret.push({
            year: m.year(),
            month: m.month(), 
            day: m.date(), 
            date: m.format('YYYY-MM-DD'), 
            events: []
        })
    }

    if (firstWeekDay > 1) {
        m.add(1- firstWeekDay, 'days');
        for(var i = 1; i < firstWeekDay; i++) {
            add();
            m.add(1, 'days');
        }
    }

    for(var i = firstWeekDay; i <= 35; i++) {
        add();
        m.add(1, 'days');
    }

    return ret;
}

export const longFormat = (data: DateString | Date) => {

    if (typeof data === 'string') {

    }

}

export const yearMonthFormat = (year: number, month: number) => {
    const m = moment({ year: year, month: month, day: 1 });
    
    return m.format('YYYY. MMMM.')
}

