import { useRef, useCallback, useReducer, Reducer } from "react";
import { Canceler } from 'axios';
import { TFunction } from 'react-i18next';

import { useAPI, debounce, Modification } from './index';
import { useValidator } from './validator';
import { check } from './check';

import { TaskType } from './types';

const fieldValidators = {
    'summary': {
        require: false,
        validators: [
            (value: string, t: TFunction) => check(value, t).required().isString().min(3).error
        ]
    }, 
    'status': {
        require: true,
        isValid: (value: string, t: TFunction) => check(value, t).required().isString().values(['TODO', 'IN_PROGRESS', 'WAITING', 'DONE', 'CLOSED', 'DELETED']).error
    }, 
    'due_date': {
        require: false,
        //isValid: (value: string, t: TFunction) => check(value, t).required().isDate().after('2021-12-01').error
    }, 
};

const emptyTask: TaskType = {
    id: undefined,
    summary: 'Task...',
    status: 'TODO',
    attachments: [], 
}

type StateType = {
    task: TaskType;
    isLoading: boolean;
    isModified: Modification;
    errorMessages: any;
}

const initialState: StateType = {
    task: emptyTask,
    isLoading: false,
    isModified: Modification.Untouched, 
    errorMessages: {}
}

const reducer = (state: StateType, action: any): StateType => {
    switch (action.type) {
        case 'INIT':
            return { task: action.payload, isLoading: state.isLoading, isModified: state.isModified, errorMessages: {} }
        case 'LOADING':
            return { ...state, isLoading: true }
        case 'LOADED':
            return { task: action.payload, isLoading: false, isModified: Modification.Untouched, errorMessages: {} }
        case 'MODIFIED':
            return { task: action.payload, isLoading: state.isLoading, isModified: Modification.Modified, errorMessages: state.errorMessages }
        case 'VALIDATED':
            return { ...state, errorMessages: action.payload }
        case 'SAVED':
            return { ...state, isModified: Modification.Saved }
        default:
            return state;
    }
}

export const useTask = (onForbidden?: Function, onError?: Function) => {

    const [state, dispatch] = useReducer<Reducer<StateType, any>>(reducer, initialState);
    const cancelRef = useRef<Canceler>();

    const { fetchData, updateData, createData } = useAPI({ cancelRef, onError, onForbidden });
    const { validate } = useValidator(fieldValidators);

    const _initTask = (_task: TaskType) => {
        dispatch({ type: 'INIT', payload: _task });
    }

    const _loadTask = (taskId: number) => {
        dispatch({ type: 'LOADING' });

        fetchData(`/api/task/${taskId}`).then(resp => {
            dispatch({ type: 'LOADED', payload: resp.data });
        }).catch((error: any) => {});
    }

    const saveTask = (task: TaskType) => {

        const taskId = task.id;

        const _task = {
            summary: task.summary,
            description: task.description,
            status: task.status,
            due_date: task.due_date,
            asset: task.asset, 
            accountable: task.accountable, 
            work_performed: task.work_performed, 
            performed_by: task.performed_by, 
            performed_at: task.performed_at, 
        };

        if (taskId) {
            updateData(`/api/task/${taskId}`, _task)
            .then((resp: any) => {
                dispatch({ type: 'SAVED' });
            })
            .catch((error: any) => {});
        } else {
            createData(`/api/task`, _task)
            .then((resp: any) => {
                dispatch({ type: 'SAVED' });
            })
            .catch((error: any) => {});
        }

    }

    const debouncedSaveTask = useCallback(
        debounce((_task: any) => {
            const _errors = validate(_task);
            if (Object.keys(_errors).length === 0) {
                _task.id && saveTask(_task);
            }
        }, 6000), 
        []
    );

    const _updateTask = (changes: any) => {
        const _task = Object.assign({}, state.task, changes);

        dispatch({ type: 'MODIFIED', payload: _task });
        debouncedSaveTask(_task);
    }

    const _saveTask = (_task: TaskType) => {
        debouncedSaveTask.cancel();
        saveTask(_task);
    }

    const _validateTask = () => {
        const _errorMessages = validate(state.task);
        dispatch({ type: 'VALIDATED', payload: _errorMessages });

        console.log('_validateTask() - _errors: ', _errorMessages);

        for (const fieldName in _errorMessages) {
            if (_errorMessages[fieldName]) return false;
        }
        
        return true;
    }

    const _cancelSaving = () => {
        debouncedSaveTask.cancel();
    }

    return { 
        task: state.task, 
        initTask: _initTask, 
        loadTask: _loadTask, 
        updateTask: _updateTask, 
        saveTask: () => _saveTask(state.task),
        cancelSaving: _cancelSaving,  
        isLoading: state.isLoading, 
        isModified: state.isModified, 
        validateTask: _validateTask,
        errors: state.errorMessages, 
    };

}

