import React, { useEffect, useRef, useState } from "react";

export const RadioInput = ({ name, value, onChange = () => {}, children }: any) => {

    const handleChange = (value: string) => {
        onChange(value, name);
    }

    return <div className='radio-group'>
        { React.Children.map(children, child => {

            return React.cloneElement(child, {
                onClick: handleChange,
                checked: value === child.props.value
            });

        }) }
    </div>;
}

export const RadioButton = ({ name, value, title, checked = false, onClick = () => {} }: any) => {
    
    const handleClick = (e: any) => {
        onClick(value);
    }

    /*
    return <div className="radio-root">
        <span className='radio-button'>
            <input id={`${name}_${value}`} type="radio" checked={checked} />
        </span>
        <label className='radio-label' onClick={handleClick}>{ title }</label>
    </div>;*/

    return <div className='radio-root2'>
        <input id={`${name}_${value}`} checked={checked} type="radio" />
        <label><span onClick={handleClick}></span>{ title }</label>
    </div>;
}

export const RadioPane = ({ name, value, checked = false, onClick = () => {}, children }: any) => {
    
    const handleClick = (e: any) => {
        onClick(value);
    }

    /*
    return <div className="radio-root">
        <input id={`${name}_${value}`} type="radio" checked={checked} />
        <div className='radio-pane' onClick={handleClick}>{ children }</div>
    </div>;*/

    return <div className='radio-root2'>
        <input id={`${name}_${value}`} checked={checked} type="radio" />
        <div className='radio-pane'>
            <span onClick={handleClick}></span>
            <div className='radio-content'>{ children }</div>
        </div>
    </div>;

}

