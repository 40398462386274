import React from "react";

export const Button = ({ variant, disabled = false, icon, size, onClick = () => {}, children }: any) => {

    const handleClick = (e: any) => {
        onClick(e);
    }

    const buttonVariant = variant ? ' btn-' + variant : '';
    const buttonSize = size ? ' btn-' + size : '';

    return (<button className={'btn' + buttonVariant + buttonSize} disabled={disabled} onClick={handleClick}>
        { icon && <><Icon name={icon} />&nbsp;</> }
        { children }
    </button>);
}


const Icon = ({ name = 'gift '}: any) => {
    return <i className={'las la-' + name + ' button-size'}></i>;
}
Button.Icon = Icon;

