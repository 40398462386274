import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';

import { SchedulingListItem } from '../../api/types';
import { DataTable, DataTableColumnProps, DataTableCellType } from '../../components';

const weekDays = {
    'MO': "monday",
    'TU': "tuesday",
    'WE': "wednesday", 
    'TH': "thursday",
    'FR': "friday",
    'SA': "saturday",
    'SU': "sunday"
};

const describe = (s: SchedulingListItem): string => {
    const { t } = useTranslation();

    if (s.trigger_type === 'TIME') {

        if (s.recurrence) {
            const rec = s.recurrence;

            if (rec.freq === 'DAY') {
                return (rec.interval === 1) ? t('translation:recurring.txtEveryDay') : t('translation:recurring.txtEveryNthDay', { day: rec.interval });
            }

            if (rec.freq === 'WEEK') {
                const weekdayName = t(`translation:calendar.${weekDays[rec.by_day!]}`);
                return (rec.interval === 1) ? 
                    t('translation:recurring.txtWeeklyWeekday', { weekday: weekdayName }) : 
                    t('translation:recurring.txtNthWeekWeekday', { weekday: weekdayName, week: rec.interval });
            }

            if (rec.freq === 'MONTH') {
                return (rec.interval === 1) ? 
                    t('translation:recurring.txtMonthlyOnDay', { day: rec.by_monthday }) : 
                    t('translation:recurring.txtNthMonthlyOnDay', { month: rec.interval, day: rec.by_monthday });
            }

        } else {
            return `Pontosan: ${s.start_date}`;
        }

    } else if (s.trigger_type === 'METER') {

        if (s.recurrence) {
            const rec = s.recurrence;
            return `Minden újabb ${rec.incremental_value}. értéknél`;
        } else {
            return s.initial_value ? `Pontos értéknél: ${s.initial_value}` : '';
        }

    }

    /*
    if (rec.month) {
        if (rec.day_in_month) {
            return rec.month === 1 ? t('translation:recurring.txtMonthlyOnDay', { day: rec.day_in_month }) : 
                t('translation:recurring.txtNthMonthlyOnDay', { month: rec.month, day: rec.day_in_month });

        } else if (rec.weekday && rec.week_in_month) {
            const weekdayName = t(`translation:calendar.${codes[rec.weekday - 1]}`);

            return rec.month === 1 ? t('translation:recurring.txtMonthlyOnWeekDay', { weekday: weekdayName, num: ordinalNumber(rec.week_in_month, t) }) : 
                t('translation:recurring.txtNthMonthlyOnWeekDay', { month: rec.month, weekday: weekdayName, num: ordinalNumber(rec.week_in_month, t) });
        }
    }*/

    return '';
}

/*
const SchedulingItem = ({ item, onClick }: any) => {

    const { id, summary, trigger_type: type } = item;

    const handleClick = () => {
        onClick && onClick(id);     
    }

    return (<tr onClick={handleClick}>
        <td className='event-title'>{ summary }</td>
        <td>{ (type === 'TIME') ? 'Idő alapú' : (type === 'METER') ? 'Használat alapú' : '' }</td>
        <td>{ describe(item) }</td>
    </tr>);

}*/

const columns: DataTableColumnProps[] = [
    { 
        name: 'name', title: 'Feladat', 
        type: DataTableCellType.String, 
        width: 100, 
    }, 
    { 
        name: 'type', title: 'Típus', 
        render: (cell: any, row: any) => <td>{ (cell === 'TIME') ? 'Idő alapú' : (cell === 'METER') ? 'Használat alapú' : '' }</td>,         
        width: 100, 
    }, 
    { 
        name: 'next', title: 'Ütemezés', 
        render: (cell: any, row: any) => <td>{ describe(cell) }</td>,         
        width: 100, 
    },
];


const SchedulingList = ({ list, onSelect }: any) => {

    const handleReloadList = () => {
        console.log('handleReloadList');
    }

    const handleClick = (row: any, index: number) => {
        onSelect && onSelect(row.id, handleReloadList);
    }

    const rows = list.map((item: any) => ({ id: item.id, name: item.summary, type: item.trigger_type, next: item }));

    return <div className='ma-table'>
        <DataTable rows={rows} columns={columns} onSelectRow={handleClick} />
    </div>

    /*
    return (<div className='ma-table'>
        <table className='ma-table'>
        <thead>
            <tr>
                { 
                    ['Feladat', 'Típus', 'Ütemezés'].map(column => {
                        return <th scope="col">{column}</th>
                    }) 
                }
            </tr>
        </thead>
        <tbody>
            { list.map((sch: any) => <SchedulingItem 
                item={sch}
                onClick={handleClick} 
                />) }
        </tbody>
        </table>
    </div>);*/

}

export default SchedulingList;