import React from "react";

const range = (from: number, to: number, step = 1) => {
    let i = from;
    const range = [];
  
    while (i <= to) {
      range.push(i);
      i += step;
    }
  
    return range;
};

function EmptyButton() {
    const style = {
        display: 'inline-block', 
        fontSize: '1rem', 
        padding: '0.75rem 1.7rem', 
    }

    return <div style={style}></div>
}

interface PaginationProps {
    totalRecords: number;
    pageLimit?: number;
    pageNum: number;
    pageNeighbours?: number;
    onChange: (nextPageNum: number) => void;
}

export default function Pagination({ totalRecords, pageLimit = 10, pageNum, pageNeighbours = 0, onChange } : PaginationProps) {

    const totalPages: number = Math.ceil(totalRecords / pageLimit);

    if (totalPages < pageNum || pageNum < 1) {
        return (<></>);
    }

    const pages: Array<number> = range(1, totalPages);

    const paging = function(newPage: number) {
        onChange && onChange(newPage);
    }


    return (<div className='pagination'>
        { pageNum > 1 ? (<button className='' onClick={() => paging(pageNum - 1)}><i className="las la-angle-left"></i></button>): <EmptyButton /> }
        {
            pages.map((page: number, index) => {
                return (<button className={pageNum === page ? 'active' : ''} onClick={() => paging(page)}>{page}</button>);
            })
        }
        { pageNum < totalPages ? (<button className='' onClick={() => paging(pageNum + 1)}><i className="las la-angle-right"></i></button>): <EmptyButton /> }

    </div>);

}
