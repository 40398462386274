import React, { useState, useEffect, useRef, useMemo } from "react";

import { useOnClickOutside } from '../../api/hooks';

interface TagType {
    id: number;
    title: string;
}


const Tag = ({ id, title, color, onRemove }: any) => {
    return (<span className={`tag ${color}`}>{title}<i className="las la-times tag-size" onClick={(e) => {onRemove && onRemove(id)}}></i></span>);
}

const DropdownItem = ({ id, title, onClick = () => {} }: any) => {
    return <li onClick={() => onClick(id)}>{title}</li>;
}

// TODO: implementálni egy alapértelmezett filtert
const filterTags = (filter: string, list: Array<string>) => {
    list.filter(item => {

    })
}

export const TagsInput = ({ value: initTags, name, dataList, placeholder, limit = 1, 
    onChange, onFilter = filterTags, onNewTag }: any) => {

    const [tags, setTags] = useState<Array<TagType>>(initTags || []);
    const [filter, setFilter] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setTags(initTags || []);
    }, [initTags]);

    useOnClickOutside(ref, () => {
        open && setOpen(false);
    });

    const handleClickInside = () => {
        !open && setOpen(true);
    }
    
    const handleRemove = (id: number) => {
        const _tags = tags.filter((tag: any) => tag.id !== id);
        setTags(_tags);
        onChange && onChange(_tags, name);
    }

    const handleChangeFilter = (e: any) => {
        const value = e.target.value;
        setFilter(value);
        //onFilter && onFilter(value);
        !open && setOpen(true);
    }

    const filteredList = useMemo(() => {
        if (!filter) return dataList;
        return dataList.filter((item: TagType) => {
            const _filter = filter.toLowerCase();
            const _title = item.title.toLowerCase();
            return _title.includes(_filter);
        });
    }, [filter, dataList]);

    const handleKeyDown = (e: any) => {
        //console.log('handleKeyDown: ', e)

        if (e.key === 'Escape') {
            open && setOpen(false);
        }

        if (e.key === 'Backspace' && (!filter || filter.length === 0) && tags) {
            open && setOpen(false);
            //console.log('törölni');
        }

        if (e.key === 'Enter') {

        }

    }

    const handleSelectItem = (id: number) => {
        setOpen(false);

        console.log('TAGS: ', tags);

        if (limit === 1 || limit > tags.length) {
         
            const _tag = dataList.find((item: any) => item.id === id);
            if (!_tag) return;

            const _tags = (limit === 1) ? [{
                id: id,
                title: _tag.title, 
            }] : [...tags, {
                id: id,
                title: _tag.title, 
            }];

            setTags(_tags);
            onChange && onChange(_tags, name);
        }

    }

    const handleNewTag = () => {
        onNewTag && onNewTag(filter);
        setFilter('');
    }

    return (<div className='dropdown'>
        <div style={{ display: 'flex',  }}>
            <div className="tagsinput" onClick={handleClickInside}>
                { tags && tags.map((tag: any) => <Tag id={tag.id} title={tag.title} color={tag.color} onRemove={handleRemove} />) }
                <input value={filter} placeholder={placeholder} onKeyDown={handleKeyDown} onChange={handleChangeFilter} />
            </div>
            { filter && <button style={{ minWidth: '120px' }} className='btn' onClick={handleNewTag}>{ 'Új címke' }</button> }
        </div>

        <div ref={ref} className={`dropdown-list ${open ? 'show' : ''}`}>
            <ul>
                { filteredList.map((item: any) => <DropdownItem {...item} onClick={handleSelectItem} />) }
            </ul>
        </div>
    </div>);

}
