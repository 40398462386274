import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router, Route, Link, Switch, Redirect } from 'react-router-dom';

import './i18n';
import './utils';

import Providers from './providers/Providers';
import { LoginPage, DashboardPage, AssetsPage, RulesPage, CalendarPage, PersonnelPage, DocumentsPage, ProcessesPage, UserSettingsPage } from './pages';
import UIKitPage from './uikit/UIKitPage';

import { useUser } from './providers/UserProvider';

const Navigaton = () => {

    const { loginName } = useUser();

    return (<Router>
        <Switch>
            <Route path='/login' component={ LoginPage } />

            <Route path="/uikit" component={ UIKitPage } />
            <Route path="/test" exact>
                Ide jön majd a teszt oldal
            </Route>

            { loginName && (<>
                <Route exact path="/" component={ DashboardPage } />
                <Route path="/assets" component={ AssetsPage } />
                <Route path="/processes" component={ ProcessesPage } />
                <Route path="/calendar" component={ CalendarPage } />
                <Route path="/personnel" component={ PersonnelPage } />
                <Route path="/documents" component={ DocumentsPage } />
                <Route path="/rules" component={ RulesPage } />
                <Route path="/usersettings" component={ UserSettingsPage } />
                </>)
            }

            <Redirect to='/login' />

        </Switch>
    </Router>);

}

const App = function() {
    return (<>
    <Providers>
        <Navigaton />
    </Providers>
    </>);
}


ReactDOM.render(
  <App />,
  document.getElementById("app")
);