import React, { useState } from "react";
import { TFunction } from 'react-i18next';

type ValidatorFunction = (value: any, t?: TFunction, form?: any) => string | undefined;

type ValidatorObject = {
    [errorCode: string] : ValidatorFunction
}

type FieldValidatorType = {
    require?: boolean;
    isValid?: ValidatorFunction;
    validators?: Array<ValidatorFunction> | ValidatorObject
}

type ValidatorListType = {
    [fieldName: string]: FieldValidatorType
}

export const useValidator = (init: ValidatorListType, t?: TFunction) => {

    const [validators, ] = useState<ValidatorListType>(init);

    const validateField = (name: string, value: any, form: any): string | undefined => {
        const fieldValidator = validators[name];

        if (fieldValidator) {
            if (fieldValidator.require && (value === null || value === undefined)) return `Missing field: ${name}`;
            
            if (fieldValidator.isValid) return fieldValidator.isValid(value, t, form);

            if (fieldValidator.validators) {
                const validators = fieldValidator.validators;

                // array of ValidatorFunction
                if (Array.isArray(validators)) {
                    for (const check of validators) {
                        const error = check(value, t, form);
                        if (error) return error;
                    }
                    return undefined;
                }

                // ValidatorObject
                for (const errorCode in validators) {
                    const check = validators[errorCode];
                    const error = check(value, form);
                    if (error) return errorCode;
                }
                return undefined;

            }

        }

        return undefined;
    }

    const validate = (form: any) => {
        const _errorMessages: any = {};

        for (const fieldName in validators) {

            const fieldValue = form[fieldName];

            if (fieldValue) {
                const errorMessage = validateField(fieldName, fieldValue, form);
                _errorMessages[fieldName] = errorMessage;
            }

        }

        /*
        setErrorMessages((prev: any) => ({
            ...prev,
            ..._errorMessages
        }));*/
        console.log('validate() - messages: ', _errorMessages)

        return _errorMessages;
    }

    return {
        validateField,
        validate, 
        //errorMessages, 
    }

}

