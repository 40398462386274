import React from "react";
import PropTypes from 'prop-types';

export default function NavbarItem(props: any) {

    const { title, iconName, to, active = false, onClick } = props;

    function handleClick(e: React.MouseEvent) {
        e.preventDefault();
        onClick && onClick(to);
    }


    return (<li className={`nav-item ${ active ? 'active' : ''}`}>
        <a href={to} onClick={handleClick}>
            <span className='menu-icon'><i className={`las la-${iconName} menu-size`}></i></span>
            <span className='menu-title'>{ title }</span>
        </a>
    </li>);
}

NavbarItem.propTypes = {
    title: PropTypes.string.isRequired,
    iconName: PropTypes.string.isRequired,
    active: PropTypes.bool,
    onClick: PropTypes.func, 
    to: PropTypes.string.isRequired
} 
