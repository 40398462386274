import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as moment from 'moment';

import translation_hu from './translations/hu-HU/translation.json';
import translation_en from './translations/en-US/translation.json';

import { ordinalNumber } from './utils';

const resources = {
    'en-US': {
        translation: translation_en
    },
    'hu-HU': {
        translation: translation_hu
    }, 
};


i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "en-US",

        fallbackLng: 'en-US',
        debug: true,
        interpolation: {
            format: function(value: any, format, lng) {

                if (format === 'formatYearMonth') {
                    return moment({ year: value.year, month: value.month }).format('YYYY. MMMM')
                }

                if (format === 'formatLongDate') {
                    return value ? moment({ year: value.year, month: value.month, day: value.day }).format('YYYY. MMMM D.') : '';
                }

                return value;
            }
        }

    });

i18n.on('languageChanged', function(lng) {
    moment.locale(lng);
});

export default i18n;
