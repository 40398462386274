import React, { useState, useRef } from "react";

import { Icon } from './index';

import { useOnClickOutside } from '../api/hooks';

const EditableTitle = ({ name, title, onChange }: any) => {

    const [edit, setEdit] = useState(false);
    const ref = useRef<HTMLInputElement>();

    useOnClickOutside(ref, () => {
        setEdit(false);
    });

    const handleClick = () => {
        setEdit(true);
    }

    const handleChange = (event: any) => {
        const value = event.target.value;
        onChange && onChange(value, name);
    }

    const handleBlur = () => {
        setEdit(false);
    }

    return edit ? <input className='editable' value={title} onChange={handleChange} onBlur={handleBlur} /> :
    <><h1 className='editable' onClick={handleClick}>{title}</h1><Icon name='pencil-alt' size='la-2x'/></>
}

export default EditableTitle;