import React, { useState, useEffect, useRef, useMemo } from "react";

import { Icon } from './index';

const TabNav = ({ id, name, selected, disabled, onSelect, children}: any) => {

    const className = selected ? 'tab selected' : (disabled ? 'tab disabled' : 'tab');

    const handleSelect = function() {
        if (!disabled) { onSelect(name); }
    }

    return <li className={className} 
        id ={id}
        role='tab' 
        aria-controls={name} 
        aria-selected={selected} 
        onClick={handleSelect}>{children}</li>
}


const TabPanel = ({ id, selected, tabNavId, children }: any) => {

    const className = selected ? 'tab-panel selected' : 'tab-panel';

    return <div className={className} 
        id={id} 
        role='tabpanel' 
        aria-labelledby={tabNavId}>
        {children}
    </div>
}


export const Tabs = ({ id, children, selected, onChange } : any) => {

    const [active, setActive] = useState(selected);

    const tabs = useMemo(() => {
        return Array.isArray(children) ? children : [children];
    }, [children])

    const handleSelect = function(name: string) {
        setActive(name);
        onChange && onChange(name);
    }

    return <div className='tab-main'>
        <ul className='tab-list' role='tablist'>
            { tabs.map((child: any, idx: number) => {

                const { name, disabled, title, icon } = child.props;
                const tabNavId = id ? (name ? `${id}-${name}` : `${id}-${idx}`) : (name ? `tabnav-${name}` : `tabnav-${idx}`);

                return <TabNav name={name} 
                    id={tabNavId}
                    selected={name === active} 
                    disabled={disabled} 
                    onSelect={handleSelect}>{ icon ? <><Icon name={icon} />&nbsp;</> : null }{title}</TabNav>
            }) }
        </ul>
        <div className='tab-content'>
            { tabs.map((child: any, idx: number) => {

                const { name, children } = child.props;
                const tabNavId = id ? (name ? `${id}-${name}` : `${id}-${idx}`) : (name ? `tabnav-${name}` : `tabnav-${idx}`);
                
                return <TabPanel id={name}
                    tabNavId={tabNavId}
                    selected={name === active}>{children}</TabPanel>
            }) }
        </div>
    </div>

}

Tabs.Tab = ({ name, disabled, title, icon }: any) => {
    return <></>
};


export const Tabs2 = ({ id, children, selected, onChange } : any) => {

    const [active, setActive] = useState(selected);

    return <div className="tabs">
  
        <input className="radiotab" name="tabs" tabIndex={1} type="radio" id="tabone" checked={true} />
        <label className="label" htmlFor="tabone">Tab One</label>
        <div className="panel" tabIndex={1}>
        <h2>Tab One Content</h2>
        <p>Tab content...</p>
        </div>
    
        <input className="radiotab" tabIndex={1} name="tabs" type="radio" id="tabtwo" />
        <label className="label" htmlFor="tabtwo">Tab Two</label>
        <div className="panel" tabIndex={1}>
        <h2>Tab Two Content</h2>
        <p>Tab content...</p>
        </div>
        
        <input className="radiotab" tabIndex={1} name="tabs" type="radio" id="tabthree" />
        <label className="label" htmlFor="tabthree" >Tab Three</label>
        <div className="panel" tabIndex={1}>
        <h2>Tab Three Content</h2>
        <p>Tab content...</p> 
        </div>
  
    </div>
}

