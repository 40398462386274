import React, { useState, useEffect, useRef } from "react";
import { Canceler } from 'axios';
import * as moment from 'moment';

import { MonthDay, monthDays } from '../../shared';
import { useAPI } from '.';


export type DatePointer = {
    year: number;
    month: number;
    day: number;
    date: string;
}

const getDatePointer = (value?: string): DatePointer | false => {

    if (value) {
        const m = moment(value);

        return m && {
            year: m.year(),
            month: m.month(), 
            day: m.date(), 
            date: m.format('YYYY-MM-DD'), 
        }
    }

    return false;
}

const getToday = (): DatePointer => {
    const m = moment();

    return {
        year: m.year(),
        month: m.month(), 
        day: m.date(), 
        date: m.format('YYYY-MM-DD'), 
    }
}

function nextMonth(date: DatePointer, offset: number = 1): DatePointer {

    const m = moment({ year: date.year, month: date.month }).add(offset, 'months');

    return {
        year: m.year(),
        month: m.month(),
        day: m.date(), 
        date: m.format('YYYY-MM-DD'), 
    }
}

export const useCalendar = (value?: string) => {

    const init = value ? getDatePointer(value) : getToday();

    if (init === false) {
        console.error('Cant parse date: ', value);
    }

    const [current, setCurrent] = useState(getToday());
    const [days, setDays] = useState(monthDays(current.year, current.month));

    const jump = (offset: number = 1) => {
        const next = nextMonth(current, offset);
        setCurrent(next);
        setDays(monthDays(next.year, next.month));
    }

    const gotoDate = (value?: DatePointer) => {
        const next = value || getToday();
        setCurrent(next);
        setDays(monthDays(next.year, next.month));
    }

    useEffect(() => {
    }, []);

    return {
        value: init as DatePointer, 
        days,
        current, 
        jump, 
        gotoDate, 
    }
}

export const useCalendarEvents = (current: DatePointer, initDays?: Array<MonthDay>, onForbidden?: Function, onError?: Function) => {

    const { year, month } = current;

    const [isLoading, setLoading] = useState(false);
    const [dates, setDates] = useState<Array<MonthDay>>(initDays || []);
    const cancelRef = useRef<Canceler>();

    const { fetchData: getData } = useAPI({ cancelRef, onError, onForbidden });

    const loadEvents = () => {

        if (year && Number.isInteger(month)) {
            const days = monthDays(year, month);
            setDates(days);
    
            getData(`/api/calendar/${year}-${month}`).then(resp => {
                const days = resp.data || [];
                setDates(days);
            }).catch((error: any) => {});

        }

    }

    useEffect(() => {
        console.log('loading events')
        loadEvents();
    }, [year, month]);

    return { 
        days: dates, 
        isLoading,
        loadEvents: loadEvents
    };
}

export const useCalendarRecurrings = (current: DatePointer, initDays?: Array<MonthDay>, onForbidden?: Function, onError?: Function) => {

    const { year, month } = current;
    const [isLoading, setLoading] = useState(false);
    const cancelRef = useRef<Canceler>();

    const [dates, setDates] = useState<Array<MonthDay>>(initDays || []);

    const { fetchData: getData } = useAPI({ cancelRef, onError, onForbidden });

    const loadEvents = () => {

        if (year && Number.isInteger(month)) {

            setDates(initDays || []);
    
            getData(`/api/calendar/${year}-${month}/recurrings`).then(resp => {
                const days = resp.data || [];
                setDates(days);
            }).catch((error: any) => {});

        }

    }

    useEffect(() => {
        console.log('loading recurring')
        loadEvents();
    }, [year, month]);

    return { 
        days: dates, 
        isLoading,
        loadEvents: loadEvents
    };
}


