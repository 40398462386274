import React, { useEffect, useRef } from "react";
import ReactFlow, { Elements, FlowElement, Node, Edge } from 'react-flow-renderer';

import { Page, PageContent, Button } from '../../components';
import { Uzemelteto, Hatosag, Dokumentum } from './CustomNodes';
import { useProcess } from '../../api/process';
import process1 from './process1.json';

const nodeTypes = {
    uzemeltetoNode: Uzemelteto,
    hatosagNode: Hatosag,
    dokumentumNode: Dokumentum,
};

const elements: Elements<any> = [
    { id: 'u1', type: 'uzemeltetoNode', 
        data: { 
            text: 'Első node', 
            alfa: 23.4, 
            onChange: (e) => { console.log('jeeeee') } 
        },
        position: { x: 300, y: 5 } },

    { id: 'd1', type: 'dokumentumNode', data: { text: 'Doc1' }, position: { x: 600, y: 15 } },
    { id: 'u1-d1', source: 'u1', sourceHandle: 'd1',  target: 'd1', },

    { id: 'h1', type: 'hatosagNode', data: { text: 'A BJ, BE vizsgálata és döntéshozatal 90 napon belül' }, position: { x: 300, y: 120 } },

    //{ id: 'u1-2', source: 'u1', target: '2', animated: true },
    // you can also pass a React component as a label
    //{ id: '2', data: { label: <div>Node 2</div> }, position: { x: 100, y: 100 } },
];

function addFunctions(elements: Elements<any>, func: Function) {

    elements.forEach((element: FlowElement) => {

        if (element.type === "uzemeltetoNode") {
            const data = element.data;
            data.onChange = func;
        }

    });
}

export default function ProcessesPage({ location }: any) {

    const { process, isLoading, saveProcess, updateProcess } = useProcess(20000);
    const flow = useRef<any>(null);

    const style = { 
        height: '64vh'
    }

    const handleLoad = (instance: any) => {
        flow.current = instance;
    }

    const handleSave = () => {
        const obj = flow.current?.toObject();
        saveProcess(obj);
    }

    const onNodeDragStop = (event: any, node: any) => {
        console.log('drag stop', node);
    }

    const handleExport = () => {
        const obj = flow.current?.toObject();
        const blob = new Blob([JSON.stringify(obj, null, 2)], {type : 'application/json'});

        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
    }

    const onElementClick = (event: React.MouseEvent, element: Node<any> | Edge<any>) => {
        console.log('clicked.... element: ', element);
    }

    const handleDoubleClick = (event: React.MouseEvent, node: Node) => {
        console.log('dbl: ', node)
    }

    const elements2 = process?.elements as Elements<any> || [];
    elements2 && addFunctions(elements2, (value: string, id: string) => {
        console.log(`changed value: ${value} - id: ${id}`);
        updateProcess(value, id);
    });

    console.log('process: ', process)

    return (<Page title='Futó folyamatok' to={ location.pathname }>
        <PageContent>
            <h4>Katasztrófavédelmi engedélyezési eljárás</h4>
            <p>Alsó és felső küszöbértékű veszélyes anyagokkal foglalkozó üzemek számára:</p>

            { process && <div style={style}>
                <ReactFlow 
                    nodeTypes={nodeTypes} 
                    elements={elements2} 
                    onElementClick={onElementClick}
                    onNodeDragStop={onNodeDragStop}
                    onLoad={handleLoad}
                    onNodeDoubleClick={handleDoubleClick}
                />
            </div> }

            <Button onClick={handleExport} variant='primary'>Export</Button>&nbsp;
            <Button onClick={handleSave} variant='primary'>Mentés</Button>

        </PageContent>
    </Page>);

}

