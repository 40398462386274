import { useState, useEffect, useRef } from "react";
import axios, { Canceler } from 'axios';

import { useAPI, debounce, Modification } from './index';

interface PersonType {
    id: number;
    name: string;
}


export const usePersonsQuery = (filter: string, onForbidden?: Function, onError?: Function) => {

    const [isLoading, setLoading] = useState(false);
    const [persons, setPersons] = useState<Array<PersonType>>([]);
    const [filteredPersions, setFilteredPersons] = useState<Array<PersonType>>([]);
    const cancelRef = useRef<Canceler>();

    const { fetchData } = useAPI({ cancelRef, onError, onForbidden });


    const loadPersonList = () => {

        fetchData(`/api/person`).then(resp => {
            const persons = resp.data || [];
            setPersons(persons);
            filterData(persons);
        }).catch((error: any) => {});

    }

    const filterData = (persons: Array<PersonType>) => {
        const _filtered = persons.filter(person => {
            return person.name?.toLowerCase().includes(filter.toLowerCase());
        });
        setFilteredPersons(_filtered);
    };

    useEffect(() => {
        loadPersonList();
    }, []);

    useEffect(() => {
        filterData(persons);
    }, [filter]);

    return { 
        persons: (filteredPersions.length > 0) ? filteredPersions : persons, 
        isLoading, 
        loadPersonList, 
    };

}

