import React, { useEffect, useMemo } from "react";
import { useTranslation } from 'react-i18next';

import { useCalendar, useCalendarEvents, useCalendarRecurrings } from '../api/calendar';
import { Button } from './Buttons';

export interface EventType {
    id: number;
    title: string;
    recurring?: boolean;
    status: string;
}

const Calendar = ({ onChangeMonth, initDate, onClickEvent, onError, onForbidden, onNewTask }: any) => {

    const { t } = useTranslation();
    const { current, days: rawDays, jump, gotoDate } = useCalendar();
    const { days: events, isLoading, loadEvents } = useCalendarEvents(current, rawDays, onForbidden, onError);
    const { days: recurrings } = useCalendarRecurrings(current, rawDays, onForbidden, onError);

    useEffect(() => {
        onChangeMonth && onChangeMonth(current);
    }, [current]);

    const days = useMemo(() => {
        return rawDays.map((day, index) => {
            return {
                ...day, 
                events: (events[index].events || []).concat(recurrings[index].events || []) 
            }
        });
    }, [rawDays, events, recurrings]);

    const handleToday = () => {
        gotoDate();
    }

    const handleNewTask = () => {
        const reloadCalendar = () => loadEvents();
        onNewTask && onNewTask(reloadCalendar);
    }

    const handleClick = (event: EventType, date: any) => {
        const reloadCalendar = () => loadEvents();
        onClickEvent && onClickEvent(event, date, reloadCalendar);
    }

    //console.log('RRRR')

    return (<div className="calendar">
    <div className="header">
        <div className="switch" style={{ width: '200px' }}>
            &nbsp;
            { /* 
            <button className="btn ">Havi</button>
            <button className="btn ">Heti</button>
            <button className="btn ">Napi</button>
            */ }
        </div>
        <div className="header-title">{ t('translation:calendar.formatYearMonth', { date: current }) }</div>

        <div className="buttons">
            <Button onClick={handleToday}>{ t('translation:calendar.btnToday') }</Button> 
            <Button variant='primary' onClick={handleNewTask}>{ t('translation:calendar.btnNewTask') }</Button> 
            <Button onClick={() => jump(-1)}><Button.Icon name='angle-left' /></Button> 
            <Button onClick={() => jump(1)}><Button.Icon name='angle-right' /></Button> 
        </div>
    </div>
    <div className="panel">
        <DayNames />
        {
            [0, 1, 2, 3, 4].map(weekNumber => {
                return (<div className="week">
                    {
                        [0, 1, 2, 3, 4, 5, 6].map(dayNumber => {
                            const index = weekNumber *  7 + dayNumber;
                            const day = days && days[index];
                            return day && <Day date={day.day} 
                                events={day.events}
                                onClick={handleClick}
                            />
                        })
                    }
                </div>);
            })
        }
        </div> 

    </div>);
}

const DayNames = () => {

    const { t } = useTranslation();

    return (<div className="day-names">
        <p>{ t('translation:calendar.monday') }</p>
        <p>{ t('translation:calendar.tuesday') }</p>
        <p>{ t('translation:calendar.wednesday') }</p>
        <p>{ t('translation:calendar.thursday') }</p>
        <p>{ t('translation:calendar.friday') }</p>
        <p>{ t('translation:calendar.saturday') }</p>
        <p>{ t('translation:calendar.sunday') }</p>
    </div>);
}

const Event = ({ id, title, color = '', onClick }: any) => {

    const handleClick = () => {
        onClick && onClick({ id, title });
    }

    return (<div className={'day-event ' + color} onClick={handleClick}>{ title }</div>);
}

const eventColor = (event: EventType) => {
    const status = event?.status;

    return (status === 'TODO') ? 'blue' : 
        (status === 'MANUAL') ? 'orange' : 
        (status === 'EXPIRED') ? 'red' :
        (status === 'CLOSED' || status === 'DELETED') ? 'grey' : 'grey';
}

const Day = ({ date, events, onClick }: any) => {

    const handleClick = (event: any) => {
        onClick && onClick(event, date);
    }

    return (<div className="day" data-date={date}>
        { events && events.map((event: EventType) => {
            return <Event 
                onClick={handleClick} 
                id={event.id} key={event.id} 
                title={event.title} 
                color={eventColor(event)} />
        }) }
    </div>);
} 

export default Calendar;
