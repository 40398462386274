import React from "react";

export default function Modal({ children, onClose, open = true }: any) {

    const clickOnModal = (e: any) => {
        e.stopPropagation();
        onClose && onClose();
    }

    const clickOnWindow = (e: any) => {
        e.stopPropagation();
    }

    const clickOnClose = (e: any) => {
        e.preventDefault();
        onClose && onClose();
    }

    const style = open ? { display: 'flex' } : { display: 'none' };

    return (<div className="modal" onClick={clickOnModal} style={style}>
        <div className="modal__window" onClick={clickOnWindow}>
            { /* <a className="modal__close" href="#" onClick={clickOnClose}>X</a> */ }
            { children }
        </div>
    </div>);


}
