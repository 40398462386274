import React, { useEffect, useRef, useState } from "react";

import { useTranslation } from 'react-i18next';

const codes = [
    "monday",
    "tuesday",
    "wednesday", 
    "thursday",
    "friday",
    "saturday",
    "sunday"
];

const getShortWeekDay = (code: string) => {
    const { t } = useTranslation();
    return t(`translation:abbreviatons.days.${code}`);
}

const DayRadio = ({ dayName, checked = false, onClick = () => {} }: any) => {
    const className = `day-radio ${checked ? 'active' : ''}`;

    return <span onClick={onClick}><div className={className}>
        { getShortWeekDay(dayName) }
    </div></span>
}

const DayInput = ({ name, value, onChange = () => {} }: any) => {
    return <div className='day-selector'>
        { ['MO','TU','WE','TH','FR','SA','SU'].map(
            (day: string, index: number) => <DayRadio dayName={codes[index]} checked={value === day} onClick={() => onChange(day, name)} />
        ) }
    </div>
}

export default DayInput;