import { useRef, useReducer, Reducer, useEffect } from "react";
import axios, { Canceler } from 'axios';

import { useAPI } from './index';
import { useUser } from '../providers/UserProvider';

type StateType = {
    form: any;
    errorMessages: any;
}

const initialState: StateType = {
    form: {
        email_address: null,
        password: null, 
        password_again: null,
        password_strength: -1, 
    },
    errorMessages: {}
}

const reducer = (state: StateType, action: any): StateType => {
    switch (action.type) {
        case 'INIT':
            return { form: action.payload, errorMessages: {} }
        case 'MODIFIED':
            return { form: action.payload, errorMessages: state.errorMessages }
        default:
            return state;
    }
}

export const useSettings = (onForbidden?: Function, onError?: Function) => {

    const user = useUser();
    const [state, dispatch] = useReducer<Reducer<StateType, any>>(reducer, initialState);

    const cancelRef = useRef<Canceler>();
    const { fetchData, updateData, createData } = useAPI({ cancelRef, onError, onForbidden });


    useEffect(() => {
        dispatch({ type: 'INIT', payload: {
            ...state.form, 
            email_address: user.emailAddress,
        } });
    }, [user]);


    const _save = () => {
        const userId = user.userId;
        updateData(`/api/user/${userId}`, {
            emailAddress: state.form.email_address,
            password: state.form.password
        })
        .then((resp: any) => {
            dispatch({ type: 'SAVED' });
        })
        .catch((error: any) => {});
    }

    const _updateSettings = (changes: any) => {
        const _form = Object.assign({}, state.form, changes);
        dispatch({ type: 'MODIFIED', payload: _form });
    }

    return {
        settings: state.form,
        updateSettings: _updateSettings,  
        saveSettings: _save
    }

}
