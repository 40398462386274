import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';

import { NavbarTop, NavbarLeft, NavbarItem } from '..';

export default function Page({ children, title, to }: any) {

    const { t } = useTranslation();
    
    const onChange = function(to: string) {
    }

    return (<>
        <NavbarTop title={ title } />
        <NavbarLeft onChange={ onChange } to={ to }>
            <NavbarItem to='/' iconName='tachometer-alt' title='Dashboard' />
            <NavbarItem to='/assets' iconName='hammer' title={ t('translation:menuLeft.assets') } active={true} />
            {/* <NavbarItem to='/processes' iconName='stream' title={ t('translation:menuLeft.processes') } active={true} /> */}
            <NavbarItem to='/calendar' iconName='calendar-alt' title={ t('translation:menuLeft.calendar') } />
            <NavbarItem to='/personnel' iconName='user-friends' title={ t('translation:menuLeft.personnel') } />
            <NavbarItem to='/documents' iconName='archive' title={ t('translation:menuLeft.documents') } />
            <NavbarItem to='/rules' iconName='exclamation' title={ t('translation:menuLeft.rules') } />
        </NavbarLeft>
        <section className='main'>
            { children }
        </section>
    </>);
}
