import * as moment from 'moment';
import { TFunction } from 'react-i18next';

 const _string = function(this: any, error: string) {
    this.error = error || ((typeof this.value !== 'string') ? 'The field {Name} must be a string' : undefined);

    this.min = function(value: number) {
        this.error = this.error || ((this.value.length < value) ? 'Minimum length is {min}' : undefined);
        return this;
    }

    this.max = function(value: number) {
        this.error = this.error || ((this.value.length > value) ? 'Maximum length is {max}' : undefined);
        return this;
    }

    this.values = function(values: Array<string>) {
        if (this.error) {
            return this;
        }

        for (const value of values) {
            if (value === this.value) return this;
        }

        this.error = `Incorrect value`;
        return this;
    }

    return this;
}

function _date(this: any, error: string) {
    this.date = moment(this.value, 'YYYY-MM-DD');
    this.error = error || (!this.date.isValid() ? 'The field {Name} must be a date' : undefined);

    this.before = function(value: string) {
        const date = moment(value, 'YYYY-MM-DD');
        this.error = this.error || (!this.date.isBefore(date) ? 'Should before {value}' : undefined);
        return this;
    }

    this.after = function(value: string) {
        const date = moment(value, 'YYYY-MM-DD');
        this.error = this.error || (!this.date.isAfter(date) ? 'Should after {value}' : undefined);
        return this;
    }

    return this;
}

function _reference(this: any, error: string) {
    this.error = error || ((typeof this.value !== 'object') ? 'The field {Name} must be an object' : undefined);

    return this;
}


type CheckObject = {
    value: any, 
    error: undefined | string,
};

const cobj: CheckObject = {
    value: undefined,
    error: undefined,    
};


const _check = function(this: any, value: any, t: TFunction) {
    this.value = value;
    this.error = undefined;
    this.t = t;

    this.required = function() {
        this.error = (this.value === null || this.value === undefined) ? 'Not found' : undefined;
        return this;
    }

    this.isString = () => _string.bind(this)(this.error);
    this.isDate = () => _date.bind(this)(this.error);
    this.isReference = () => _reference.bind(this)(this.error);

    return this;
}

export const check = _check.bind(cobj);
