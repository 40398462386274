import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from 'react-i18next';

import { Page, PageContent, Calendar, EventType, Dialog } from '../../components';
import { useModal } from '../../providers/ModalProvider';
import { useToast } from '../../providers/ToastProvider';
import TaskDetails from './TaskDetails'

export default function CalendarPage({ location, history }: any) {
    
    const { t } = useTranslation();
    const modal = useModal();
    const { showToast } = useToast();

    const handleCloseModal = (reloadCalendar: Function) => {
        modal.closeModal();
        reloadCalendar();
    }

    const handleChangeMonth = (date: any) => {
    }

    const handleClickEvent = (event: EventType, date: any, reloadCalendar: Function) => {
        modal.showModal(<TaskDetails id={event.id} onClose={() => handleCloseModal(reloadCalendar)} />);
    }

    const handleOnForbidden = () => {
        history.push("/login");
    }

    const handleNewTask = (reloadCalendar: Function) => {
        modal.showModal(<TaskDetails id={null} onClose={() => handleCloseModal(reloadCalendar)} />);
    }

    return (<Page title={ t('translation:pages.calendar') } to={ location.pathname }>
        <PageContent>

            <Calendar onChangeMonth={handleChangeMonth} 
                onClickEvent={handleClickEvent} 
                onError={() => showToast( t('translation:error.msgCouldnotLoadCalendar') )}
                onForbidden={handleOnForbidden} 
                onNewTask={handleNewTask} />

        </PageContent>
    </Page>);
}
