import React, { useState, useRef, useEffect, useMemo } from "react";

import { Pagination, Button, SearchInput, DropdownList } from '../index';
import { DataTable } from './DataTable';

const DEFAULT_PAGE_LIMIT = 15;
const DEFAULT_PAGE = 1;

const ComplexDataTable = ({ data, columns, mapDataToTablePage, filterData, onSelectRow, t, actions }: any) => {

    const [pageNum, setPageNum] = useState<number>(DEFAULT_PAGE);
    const [pageLimit, setPageLimit] = useState(DEFAULT_PAGE_LIMIT);
    const [filter, setFilter] = useState<string>('');
    const total = useRef<number>(0);

    const rows = useMemo(() => {
        const filteredData = filterData(data, filter);
        total.current = filteredData.length;
        return mapDataToTablePage(filteredData, t, pageNum, pageLimit);
    }, [filter, pageNum, pageLimit, data]);

    const handlePagination = (newPage: number) => {
        setPageNum(newPage);
    }

    const handleFilter = (value: string) => {
        setFilter(value);
        setPageNum(1);
    }

    const handleChangePageLimit = (value: string) => {
        const _value = parseInt(value);
        if (!isNaN(_value)) {
            setPageLimit(_value);
            setPageNum(1);
        }
    }

    return (<div className='ma-table'>
        <div className='ma-table-controls'>
            <SearchInput name='ma-search' onSearch={handleFilter} />
            <div className="ma-table-actions">
                { actions && actions.map((action: any) => {
                    return <Button variant='primary' onClick={action.onClick}>{action.title}</Button>
                }) }
                <DropdownList name='page_limit' 
                    onChange={handleChangePageLimit}
                    value={pageLimit.toString()}
                    dataList={[
                        { id: '5', title: '5'}, 
                        { id: '15', title: '15'}, 
                        { id: '30', title: '30'}, 
                        { id: '50', title: '50'}, 
                    ]} 
                    />
            </div>
        </div>
        <DataTable rows={rows} columns={columns} onSelectRow={onSelectRow} />
        <Pagination totalRecords={total.current} pageLimit={pageLimit} pageNum={pageNum} pageNeighbours={1} onChange={handlePagination} />
    </div>);
}

export default ComplexDataTable;