import axios, { Canceler } from 'axios';

export { useOnClickOutside } from './hooks';

export const authenticate = (loginName: string, password: string): Promise<any> => {

    const formData = new FormData();
    formData.append('userName', loginName);
    formData.append('password', password);

    return axios.post('/login', {
        login: loginName,
        pass: password
    }).then((resp: any) => {

        console.log('logged in: ', resp)
        const data = resp.data;

        return {
            userId: data.userId,
            personId: data.personId, 
            loginName: data.loginName,
            emailAddress: data.emailAddress, 
            fullName: data.fullName,
            avatarURL: data.avatarURL, 
            language: data.language, 
        };
    
    }).catch(error => {
        console.log('error: ', error);
        throw error;
    });

}

export const logout = (): Promise<any> => { 

    return axios.post('/logout'
    ).then((resp: any) => {

        console.log('logged out: ', resp)
        return;
    
    }).catch(error => {
        console.log('error: ', error);
        throw error;
    });

}

export { useAPI } from './util';
export { startUpload, uploadToS3, updateStatus, getDownloadURL } from './binobject';


export const debounce = (func: Function, timeout: number = 2000): any => {

    let timer: NodeJS.Timeout;

    const cancel = () => {
        timer && clearTimeout(timer);
        timer && console.log('cancelled debounce')
    }

    const debounced = (...args: any[]) => {
        timer && clearTimeout(timer);
        timer && console.log('clearTimer')
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };

    debounced.cancel = cancel;

    return debounced;
}


export enum Modification {
    Untouched = 0, 
    Modified = 1, 
    Saved = 2
}



