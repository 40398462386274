import React, { useState } from "react";


export default function Form({ children, ...props }: any) {

    return (<form {...props}>
        { children }
    </form>);

}

Form.Group = ({ children, ...prop}: any) => {
    return (<div className="form-group">
        { children }
    </div>);
}

Form.Label = ({ children, ...prop}: any) => {
    return (<label>{ children }</label>);
}


Form.Message = ({ value, variant = 'normal' }: any) => {
    const className = `message${variant === 'normal' ? '' : (variant === 'error' ? ' invalid' : '' ) }` 
    return (<span className={className}>{ value }</span>);
}
