
let log: Array<string> = [];

const saveNow = () => {
    log.push('----');
    console.log('saving: ', log);

    setTimeout(() => { 
        const index = log.findIndex((item, index) => (item === '----'));
        if (index && log.length > index) {
            console.log('cutting the list')
            log = log.slice(index + 1);

            console.log('after saving: ', log);
        }
    }, 1500);

}

export const a = (...args: Array<any>) => {

    return;

    /*
    const entry = args.join(' ');

    console.log('action: ', entry);
    log.push(entry);
    console.log('len = ', log.length);

    if (log.length > 15) {
        saveNow();
    }*/

}
