import React, { useMemo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useToast } from '../../providers/ToastProvider';
import { useModal } from '../../providers/ModalProvider';
import { a } from '../../api/actionlog';

import { Page, PageContent, 
    Button, DropdownList, SearchInput, 
    ComplexDataTable, DataTableRowData, DataTableColumnProps, DataTableCellType, DataTableRowType, 
    Pagination } from '../../components';

import AssetDetails from './AssetDetails'
import TaskScheduler from './TaskScheduler';

import { useAssetsQuery } from '../../api/asset';
import { today } from '../../utils';

const createAssetName = (name: string, model: string, make: string) => {
    return `${name} ${ model ? '(' + model + ')' :  '' }`;
}

// TODO: ezt továbbfejleszthetjük, hogy typo-kat is kezeljen: ű -> ü, kötőjel, stb.
// TODO: kereshetnénk más mezőkben is: groups, inventory_number, stb.
const includes = (data: string | Array<string>, query: string): boolean => {
    if (!data || !query) return false;
    const _query = query.toLowerCase();
    
    if (Array.isArray(data)) { 
        if (data.length === 0) return false;

        return data.find((item: string) => {
            const _item = item.toLowerCase();
            return _item.includes(_query);
        }) ? true : false;
    }

    const _str = data.toLowerCase();
    return _str.includes(_query);
}

const filterAsset = (row: any, filter: string): boolean => {
    return includes([row.name, row.information], filter);
}

const filterData = (data: Array<any>, filter: string): Array<any> => {
    if (!filter || filter.length === 0) return data;
    return data.filter(record => filterAsset(record, filter));
}

const mapDataToTablePage = (data: Array<any>, t: any, pageNum: number, pageLimit: number): Array<DataTableRowData> => {

    const offset = (pageNum - 1) * pageLimit;
    const slicedData = data.slice(offset, offset + pageLimit);

    const rows: Array<DataTableRowData> = slicedData.map(record => {

        return {
            'id': record.id,  
            'name': createAssetName(record.name, record.model, record.make),
            'groups': record.groups?.map((group: any) => group.name),
            'information': record.information,
            'next': record.compliance, 
        };
     
    });

    return rows;
}

const generateColumnProps = (t: any) : DataTableColumnProps[] => {
 
    const modal = useModal();

    const handleClick = (e: any, row: DataTableRowType) => {
        e.stopPropagation();

        const initTask = {
            summary: 'Új feladat',
            status: 'TODO', 
            due_date: today(), 
            asset: {
                id: row.id, 
                name: row.name
            }, 
        }

        row && modal.showModal(<TaskScheduler id={null} init={initTask} onClose={() => modal.closeModal() } />);
    }

    const columns: DataTableColumnProps[] = [
        { 
            name: 'name', title: t('translation:asset.lblDescription'), 
            render: (data: any) => (<td>{ data }</td>), 
            width: 100, 
        }, 
        { 
            name: 'groups', title: t('translation:asset.lblGroups'), 
            type: DataTableCellType.Labels, 
            width: 100, 
        },
        { 
            name: 'information', title: t('translation:asset.lblInformation'), 
            type: DataTableCellType.String, 
            width: 100, 
        }, 
        { 
            name: 'next', title: t('translation:asset.lblNextTasks'), 
            render: (cell: any, row: any) => <td><button onClick={(e) => handleClick(e, row)} className='btn btn-table'>{ t('translation:asset.btnNewScheduling') }</button></td>, 
            width: 100, 
        },
    ];

    return columns;
}

export default function AssetsPage({ location, history }: any) {

    const { t, i18n } = useTranslation();
    const { showToast } = useToast();
    const modal = useModal();
    
    const handleOnForbidden = () => {
        history.push("/login");
    }

    const { assets, isLoading, loadAssets } = useAssetsQuery('', handleOnForbidden, () => showToast( t('translation:error.msgCouldnotLoadAssets') ));

    const columns = useMemo(() => {
        return generateColumnProps(t);
    }, [i18n.language]);

    const handleCloseModal = (reloadAssets: Function) => {
        a('close new asset dialog');
        modal.closeModal();
        reloadAssets();
    }

    const onSelectRow = (row: DataTableRowType, index: number) => {
        a('select asset row', row.id);
        row && modal.showModal(<AssetDetails id={row.id} onClose={() => handleCloseModal(loadAssets)} />);
    }

    const handleNewAsset = () => {
        a('open new asset dialog');
        modal.showModal(<AssetDetails id={null} onClose={() => handleCloseModal(loadAssets)} />);
    }

    return (<Page title={ t('translation:pages.assets') } to={ location.pathname }>
        <PageContent>
            <ComplexDataTable 
                data={assets}
                columns={columns}
                mapDataToTablePage={mapDataToTablePage}
                filterData={filterData}
                onSelectRow={onSelectRow} 
                t={t}
                actions={[
                    {
                        title: t('translation:asset.btnNewAsset'),
                        onClick: handleNewAsset
                    }
                ]}
            />
        </PageContent>
    </Page>);

}

