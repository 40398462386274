import axios, { Canceler } from 'axios';

const handleError = (onError: Function, onForbidden: Function) => (error: any) => {

    if (error.response.status === 403) {
        onForbidden();
    } else {
        onError(error);
    }

    if (axios.isCancel(error)) {
      //this is now expected
      console.log('Axios cancelled....');
    }

    return Promise.reject(error);
};

export const useAPI = ({ 
    cancelRef,
    onError, 
    onForbidden, 
}: any = {
    setLoading: () => {},
    cancelRef: null,
    onError: () => {}, 
    onForbidden: () => {}, 
}) => {

    const fetchData = (url: string, options?: any) => {

        return axios.get(url, {
            cancelToken: new axios.CancelToken(c => {
                cancelRef.current = c;
            })
        })
        .catch(handleError(onError, onForbidden));

    }

    const updateData = (url: string, data: any, options?: any) => {

        return axios.put(url, data, {
            cancelToken: new axios.CancelToken(c => {
                cancelRef.current = c;
            })
        })
        .catch(handleError(onError, onForbidden));

    }

    const createData = (url: string, data: any, options?: any) => {

        return axios.post(url, data, {
            cancelToken: new axios.CancelToken(c => {
                cancelRef.current = c;
            })
        })
        .catch(handleError(onError, onForbidden));

    }

    const cancelAxios = () => {
        cancelRef.current && cancelRef.current();
    }

    return {
        fetchData,
        updateData, 
        createData, 
        cancel: cancelAxios, 
    }

};

