import React, { useEffect, useRef, useState } from "react";

const SearchInput = ({ name, placeholder, autoFocus = false, onSearch, inputRef, ...props }: any) => {

    const [value, setValue] = useState('');
    const ref = inputRef || useRef<HTMLInputElement>(null);

    useEffect(() => {
        autoFocus && setTimeout(() => ref && ref.current?.focus(), 100);
    }, [autoFocus])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const _value = event.target.value;
        setValue(_value);
    }

    const handleSearch = () => {
        onSearch && onSearch(value, name);
    }

    const handleErase = () => {
        setValue('');
        onSearch && onSearch('', name);
    }

    const handleKeyDown = (e: any) => {
        const keyCode = e.keyCode;

        if (keyCode === 13) {
            e.preventDefault();
            handleSearch();
        } else if (keyCode === 27) {
            e.preventDefault();
            handleErase();
        }

    }

    return <div className='input-group'>
        <input className='form' name={name} 
            ref={ref}
            type='text' 
            value={value}
            placeholder={placeholder} 
            onChange={handleChange} 
            onKeyDown={handleKeyDown} />
        <span className='icon' onClick={handleErase}><i className="las la-times"></i></span>
        <span className='icon' onClick={handleSearch}><i className="las la-search"></i></span>
    </div>

}

export default SearchInput;
