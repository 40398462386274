
export const setCaret = (el: Node, pos: number) => {

    var range = document.createRange();
    var sel = window.getSelection();
    
    range.setStart(el, pos);
    range.collapse(true);
    
    sel!.removeAllRanges();
    sel!.addRange(range);
    
}

export const isEditorChar = (str: string) => {
    if (str.length !== 1) return false;

    const code = str.charCodeAt(0);
    if (
        (code >= 32 && code <= 126) ||
        ("áéíóöőúüűÁÉÍÓÖŐÚŰ".indexOf(str) !== -1)
    ) { 
        return true;
    }

    return false;
}

export const h = (nodeName: string, attrs?: any, ...children: any) => {

    if (nodeName === '#text') return document.createTextNode(children[0]);

    const el = document.createElement(nodeName);

    for (const key in attrs) {
        el.setAttribute(key, attrs[key]);
    }

    children.forEach((child: any) => {
        if (typeof child === 'string') {
            el.appendChild(document.createTextNode(child));
        } else {
            el.appendChild(child);
        }
    });

    return el;
}
