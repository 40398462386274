import React, { useState } from 'react';

import { DialogToasts } from './DialogToasts';
import { Icon } from '../index';

export const CloseButton = ({ onClose }: any) => {

    const handleClose = () => {
        onClose && onClose();
    }

    return (<div className='close-btn' onClick={handleClose}>
        <Icon name='times-circle' size='la-2x' />
    </div>);
}

const Dialog = ({ header, headerRightButtons, footer, onClose = () => {}, errorMessages, children }: any) => {

    return (<div className='dlg-wrapper wide tall'>
        <div className='dlg-header'>
            <div className='dlg-left'>{ header }</div>
            <div className='dlg-right-buttons'>{headerRightButtons}</div>
        </div>
        <div className='dlg-content'>
            { children }
        </div>
        <div className='dlg-footer'>
            { footer }
        </div>
        { /* errorMessages && errorMessages.length && <DialogToasts messages={errorMessages} /> */ }
    </div>);
};

export default Dialog;