import React, { useReducer, Reducer } from "react";
import { useTranslation } from 'react-i18next';

import { calculatePasswordStrength } from '../utils';
import { useSettings } from '../api/settings';

import { Page, PageContent, Form, Text, PasswordInput, PasswordStrength, Button } from '../components';


export default function UserSettingsPage({ location }: any) {

    const { t } = useTranslation();
    const { settings, updateSettings, saveSettings } = useSettings();

    const handleChange = (value: string, name: string) => {

        if (name === 'password') {
            const strength = calculatePasswordStrength(value);
            updateSettings({ 
                [name]: value, 
                password_strength: strength 
            });
            return;
        }

        updateSettings({ [name]: value });
    }

    const handleSave = () => {
        saveSettings();
    }

    const { password, password_again, password_strength, email_address } = settings;
    const saveable = ((password === password_again) && (password_strength > 33));

    return (<Page title={ t('translation:pages.usersettings') } to={ location.pathname }>
        <PageContent>

            <div className='row'>
                <div className='half'>
                    <Form.Group>
                        <Form.Label>{ t('translation:settings.lblEmail') }</Form.Label>
                        <Text name='email' value={email_address} onChange={handleChange} />
                    </Form.Group>
                    <div className='row'>
                        <div className='half'>
                            <Form.Group>
                                <Form.Label>{ t('translation:settings.lblPassword') }</Form.Label>
                                <PasswordInput name='password' onChange={handleChange} value={password} />
                            </Form.Group>
                        </div>
                        <div className='half'>
                            <Form.Group>
                                <Form.Label>{ t('translation:settings.lblPwdAgain') }</Form.Label>
                                <PasswordInput name='password_again' onChange={handleChange} value={password_again} />
                            </Form.Group>
                        </div>
                    </div>

                    <div className='row'>
                        { 
                            (password !== password_again) ? <div style={{ padding: '10px', color: 'red' }}>Nem egyezik a két jelszó.</div> : 
                            (password && password !== '') ?
                            <Form.Group>
                                <Form.Label>{ t('translation:settings.lblPwdStrength') }</Form.Label>
                                <PasswordStrength value={password_strength} strengths={[
                                    { level: 33, title: t('translation:settings.strengths.titleWeak'), color: '#fe4758' }, 
                                    { level: 66, title: t('translation:settings.strengths.titleMedium'), color: '#fea500' }, 
                                    { level: 100, title: t('translation:settings.strengths.titleStrong'), color: '#23ac5e' }, 
                                ]}
                            />
                            </Form.Group> : ''
                        }
                    </div>

                    <div className='row'>
                        <Button variant='primary' disabled={!saveable} onClick={handleSave}>{ t('translation:settings.btnSave') }</Button>
                    </div>


                </div>
            </div>

        </PageContent>
    </Page>);
}
