import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

import { useOnClickOutside } from "../api/hooks";

/*
const Dropdown = ({ children, onClick }: any) => {

    const handleClick = (e) => {
        onClick && onClick(e);
    }

    return (<div className='dropdown'>
        { children }
    </div>);
}

const Toggle = ({ children }: any) => {
    return (<></>);
}
Dropdown.Toggle = Toggle;

export default Dropdown;
*/
// -------

export const DropdownButtons = ({ name, defaultValue, list, onChange }: any) => {

    const [open, setOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    useOnClickOutside(ref, () => {
        setOpen(false);
    });

    const handleSelect = (e: any) => {
        e.stopPropagation();
        setOpen(false);

        const data = e.target.dataset.value;
        onChange && onChange(data, name);
    }

    const handleOpen = () => {
        setOpen(prev => !prev);
    }

    const valueInList = list.find((item: string) => item === defaultValue)

    return (<div className='dropdown'>
        <button className='btn primary' onClick={handleOpen}>{ valueInList ? defaultValue : 'Válassz...' }</button>
        <div ref={ref} className={`dropdown-list ${open ? 'show' : ''}`}>
            <ul>
                { list && list.map((text: string) => <li data-value={text} onClick={handleSelect}>{ text }</li>) }
            </ul>
        </div>
    </div>);

}

/**
 * Dropdown component to select simple list items.
 * 
 * @component
 * @example
 * <DropdownList name='fieldname' value={value} 
 *      dataList={[
 *          { id: 'FIRST', title: 'First' }, 
 *          { id: 'SECOND', title: 'Second' }, 
 *      ]}
 *      onChange={handleChange}
 * />
 * 
 */
export const DropdownList = ({ name, dataList, value = '', onChange = () => {} }: any) => {

    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    useOnClickOutside(ref, () => {
        open && setOpen(false);
    });

    const handleOpen = () => {
        setOpen(true);
    };

    const handleSelect = (e: React.MouseEvent) => {
        e.stopPropagation();
        setOpen(false);

        const value = e.currentTarget.getAttribute('data-value');
        onChange(value, name);
    };

    const title = dataList && dataList.find((item: any) => item.id === value)?.title || t('translation:details.titleChoose');
    
    return (<div className='dropdown'>
        <div className="input-group">
            <input className="form" name={name} value={title} />
            <span className="icon" onClick={handleOpen}><i className="las la-angle-down"></i></span>
        </div>

        <div ref={ref} className={`dropdown-list ${open ? 'show' : ''}`}>
            <ul>
                { dataList && dataList.map((item: any) => <li data-value={item.id} onClick={handleSelect}>{item.title}</li>) }
            </ul>
        </div>
    </div>);

}
