import React, { useEffect, useRef, useState, useMemo } from "react";

import { Button } from '../index'
import { useOnClickOutside } from '../../api/hooks';

const UnitInput = ({ name, unitName, placeholder, value, units, unit = '',   
    autoFocus = false, inputRef, 
    onChange, onEnter = () => {}, 
    ...props }: any) => {

    const isControlled = typeof onChange === 'function';
    //console.log(`isControlled: ${isControlled} - value: ${value}`)

    const isModified = useRef(false);
    const ref = inputRef || useRef<HTMLInputElement>(null);
    const refDropdown = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        autoFocus && setTimeout(() => ref && ref.current?.focus(), 100);
    }, [autoFocus])

    useOnClickOutside(refDropdown, () => {
        open && setOpen(false);
    });

    const handleClick = () => {
        setOpen(prev => !prev);
    }

    const handleSelect = (e: React.MouseEvent) => {
        e.stopPropagation();
        setOpen(false);

        const _unit = e.currentTarget.getAttribute('data-value') as string;

        console.log('changed: ', _unit)
        onChange && onChange(_unit, unitName);
    }

    const handleKeyDown = (event: any) => {
        const key = event.key;
        
        if (key === 'Backspace') return;

        if (key === 'Enter') {
            const _value = isControlled ? value : ref.current.value;
            isModified.current = false;
            onEnter && onEnter(_value, name);
            ref.current.blur();
        }

        if (key === 'Escape') {
            if (!isControlled) ref.current.value = value;
        }

        if (key < '0' || key > '9') {
            event.preventDefault();
            return;
        } else {
            isModified.current = true;
        }

    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const _value = event.target.value;
        onChange && onChange(_value, name);
    }

    const handleFocus = () => {
        //setPrevValue(value);
    }

    const handleBlur = (event: any) => {
        if (isModified.current === true) {
            if (!isControlled) ref.current.value = value;
        }
    }

    const unitTitle = useMemo(() => {
        return units && units.find((item: any) => item.id === unit)?.title || 'n/a';
    }, [unit, units]);

    return (<div className='dropdown'>
        <div className='input-group'>
            <input className='form' name={name} 
                ref={ref}
                type='text' 
                {...(isControlled ? value : {defaultValue: value} )}
                placeholder={placeholder} 
                autoComplete='off'
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleChange}
                onKeyDown={handleKeyDown} 
            />
            <div className='append' onClick={handleClick}>{unitTitle}</div>
        </div>
        <div ref={refDropdown} className={`dropdown-list ${open ? 'show' : ''} right`}>
            <ul>
                { units && units.map((item: any) => <li data-value={item.id} onClick={handleSelect}>{item.title}</li>) }
            </ul>
        </div>
    </div>);
}

export default UnitInput;
