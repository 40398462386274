import React from "react";

import Tippy from '@tippyjs/react';

const Helpy = ({ content }: any) => {
    return <Tippy content={<span>{content}</span>} delay={[100, 200]}>
        <i className={'las la-question button-size'}></i>
    </Tippy>
}

export default Helpy;