import React, { useRef } from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { Button, Text, Form } from '../components';

import { authenticate } from '../api';
import { useUser } from '../providers/UserProvider';

const defaultEmail = ''; // 'nb@blx.hu';
const defaultPassword = ''; // 'abc123';

export default function LoginPage({ location }: any) {

    const { t, i18n } = useTranslation();
    const user = useUser();
    const history = useHistory();

    const inputUser = useRef<HTMLInputElement>(null);
    const inputPass = useRef<HTMLInputElement>(null);

    const styles = {
        backgroundImage: 'url(images/bg-01.jpg)'
    }

    const handleLogin = (e: any) => {
        e.preventDefault();

        if (inputUser && inputUser.current && inputPass && inputPass.current) {

            const userName = inputUser.current.value;
            const password = inputPass.current.value;

            authenticate(userName, password).then(userData => {

                user.setUser(userData);
                i18n.changeLanguage(userData.language);

                history.push('/');
                
            }).catch(error => {
                console.error(error);
            });

        }

    }

    return (<div className='login-container' style={styles} >
        <div className='login-wrap'>
            <div className="login-logo">
                <img src='/images/logo.png' />
            </div>
            <Form>
                <Form.Group>
                    <Form.Label>{ t('translation:loginForm.email') }</Form.Label>
                    <Text inputRef={inputUser} name='userName' defaultValue={defaultEmail} />

                    <Form.Label>{ t('translation:loginForm.password') }</Form.Label>
                    <Text inputRef={inputPass} name='password' type='password' defaultValue={defaultPassword} />
                    <br/>
                    <Button variant='primary' onClick={handleLogin}>{ t('translation:loginForm.login') }</Button>
                </Form.Group>
            </Form>

        </div>

    </div>);
}
