import * as moment from 'moment';
import { useTranslation } from 'react-i18next';

declare global {
    interface String {
        splice(idx: number, rem: number, str: string): string;
    }
}

String.prototype.splice = function(idx, rem, str) {
    return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
};

export {}

export const today = () => {
    const now = moment();
    return now.format('YYYY-MM-DD');
}

export const thisWeek = () => {
    const now = moment();
    return now.isoWeekday();
}

export const thisDateOfMonth = () => {
    const now = moment();
    return now.date();
}

export const shortOrdinalNumber = (value: number | string) => {
    const { t, i18n } = useTranslation();

    const num = Number.isInteger(value) ? value : Number.parseInt(value as string);

    if (!Number.isNaN(num) && value <= 10 && value >= 1) {
        if (i18n.language === 'en-US') {
            return t('translation:ordinalNumber.abbreviatons');
        }

        return `${num}.`;
    }

    return '';
}

export const ordinalNumber = (value: number | string, trans?: Function): string => {

    let t = trans;
    if (!t) {
        const { t: trans } = useTranslation();
        t = trans;
    }

    const num = Number.isInteger(value) ? value : Number.parseInt(value as string);

    if (!Number.isNaN(num) && value <= 10 && value >= 1) {
        const ttt = t(`translation:ordinalNumber.text.${num}`);
        //console.log(`_${ttt}_`);
        return ttt;
    }

    return '';
}


const sigmoid = (x: number, shift = 0, a = 1) => {
    return 1 / (1 + Math.exp(-a * (x - shift)));
}

export const calculatePasswordStrength = (passwd: string) => {

    let chars = [
        '0123456789',
        'abcdefghijklmnopqrstuvwxyz', 
        'ABCDEFGHIJKLMNOPQRSTUVWXYZ', 
        '!"#$%*[]{}~^@&\''
    ];

    const possibleChars = chars.map((str: string, index: number) => {

        for (var i = 0; i < passwd.length; i++) {
            if (str.includes(passwd.charAt(i))) return true;
        }
        return false;

    }).reduce((sum: number, type: boolean, index: number) => {
        if (type) return sum + chars[index].length;
        return sum;
    }, 0);

    const attempts = Math.pow(possibleChars, passwd.length);
    const s = sigmoid(Math.pow(attempts, 1/10), 50, 0.05);

    return Math.round(s * 100);
}
