import axios, { Canceler } from 'axios';


export const startUpload = (fileName: string, contentType: string): Promise<any> => {

    return axios.post('/api/object', {
        fileName,
        contentType, 
    }).then((resp: any) => {

        const { id, url, fields } = resp.data;

        return {
            id, url, fields
        }

    }).catch(error => {
        console.log('error: ', error);
        throw error;
    });

}

export const uploadToS3 = (file: File, url: string, fields: object, onProgress = (data: any) => {}, cancelToken = (token: any) => {}) => {
    const formData = new FormData();

    formData.append("Content-Type", file.type);
    Object.entries(fields).forEach(([k, v]) => {
        formData.append(k, v);
    });
    formData.append('File', file);

    return axios.post(url, formData, {
        onUploadProgress: onProgress,
        cancelToken: new axios.CancelToken(c => {
            cancelToken(c);
        })
    }).then((resp: any) => {
        return resp.data;    
    }).catch(error => {
        console.error(error);
        if (!axios.isCancel(error)) {
            console.log('error: ', error);
            throw error;
        }
    });

}

export const updateStatus = (id: number, status: string): Promise<any> => {

    if (!id) return Promise.reject('File object id is missing.');

    return axios.patch(`/api/object/${id}`, {
        status,
    }).then((resp: any) => {

        return resp.data;    

    }).catch(error => {
        console.log('error: ', error);
        throw error;
    });

}

export const getDocuments = (): Promise<any> => {

    return axios.get(`/api/object`).then((resp: any) => {
        return resp.data;
    }).catch(error => {
        console.log('error: ', error);
        throw error;
    });

}

export const getDownloadURL = (id: string): Promise<any> => {

    if (!id) return Promise.reject('File object id is missing.');

    return axios.get(`/api/object/${id}`).then((resp: any) => {

        const data = resp.data;
        return data.url;

    }).catch(error => {
        console.log('error: ', error);
        throw error;
    });

}
