import React, { useState } from 'react';

import MALogo from './MALogo';

const Icon = ({ name = 'gift ', size = ''}: any) => {

    if (name === 'malogo') return <MALogo />

    const className = 'las la-' + name + ' ' + size;
    return (<i className={className}></i>);
}

export default Icon;